<template>
  <div class="app-container" style="padding: 0 15px;max-width: 600px;">
    <!-- 弹窗 -->
    <el-dialog width="70%" :modal="false" :title="title" :visible.sync="showArticle" :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false">
      <div>
        <div v-html="content"></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="close">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

 <script>
 import {getDataByCode} from '@/api/user'

  export default {
    data() {
      return {
        title: null,
        content: null
      }
    },
    props: ['code', 'showArticle'],
    watch: {
      code: {
        handler(newVal) {
          if (newVal === 'agreement' || newVal === 'privacy') {
            getDataByCode({code: newVal})
                .then(res => {
                  this.content = res.data.content
                  this.title = newVal === 'agreement' ? '用户协议' : '隐私政策'
                })
          }
        }
      }
    },
    methods: {
      close() {
        this.$emit('close', false)
      },
    }
  }
 </script>
