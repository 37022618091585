import service from '@/utils/requrest'
const api = {
    userLogin: '/api/login/login',
    userInfo: '/api/store/getInfo',
    loginCode: '/api/sms/sendCode',
    taskList: '/api/task/getList',
    addTask: '/api/task/add',
    taskDetail: '/api/task/getDetail',
    getDevicesSelect: '/api/task/getDevicesData',
    getDevicesRank: '/api/task/getRanking',
    detailType: '/api/store/getBillingType',
    detailList: '/api/store/getBillingList',
    getDataByCode: '/api/article/getDataByCode'
}
/**
 * 请求数据
 * @param   object
 * @returns Promise
 */

export const userLogin = (data) => {
    return service({
        method: 'post',
        url: api.userLogin,
        data
    })
}

export const userInfo = (params) => {
    return service({
        method: 'get',
        url: api.userInfo,
        params
    })
}

export const loginCode = (data) => {
    return service({
        method: 'post',
        url: api.loginCode,
        data
    })
}

export const taskDetail = (params) => {
    return service({
        method: 'get',
        url: api.taskDetail,
        params
    })
}

export const addTask = (data) => {
    return service({
        method: 'post',
        url: api.addTask,
        data
    })
}

export const getDevicesRank = (data) => {
    return service({
        method: 'post',
        url: api.getDevicesRank,
        data
    })
}

export const taskList = (params) => {
    return service({
        method: 'get',
        url: api.taskList,
        params
    })
}

export const getDevicesSelect = (params) => {
    return service({
        method: 'get',
        url: api.getDevicesSelect,
        params
    })
}

export const detailType = (data) => {
    return service({
        method: 'post',
        url: api.detailType,
        data
    })
}

export const detailList = (params) => {
    return service({
        method: 'get',
        url: api.detailList,
        params
    })
}

export const getDataByCode = (params) => {
    return service({
        method: 'get',
        url: api.getDataByCode,
        params
    })
}