<template>
    <div class="mp-aso__mobile">
        <div class="mp-aso__banner">
            <el-link :underline="false" :href="smallBanner.link">
                <el-image :src="smallBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
            <!-- <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/mpAso/MPASO__banner.png'" style="width: 100%;height: auto;"></el-image> -->
        </div>

        <div class="mp-aso__result">
            <div class="content__title">
                <span>小程序推广能为你带来什么</span>
            </div>
            <div class="result__item" 
                v-for="(item, index) in brings"
                :key="index"
                :style="{backgroundImage: 'url('+item.h5_background_img+')'}">
                <div class="result__box">
                    <div class="result__title">
                        <span>{{ item.title || '' }}</span>
                    </div>
                    <div class="result__options">
                        <div class="result__option__item" v-for="(option, ort) in item.content" :key="ort">
                            <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/mpAso/icon__checked.png'" style="width: 12px;height: 8px;margin-right: 5px;"></el-image>
                            <span>{{ option }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mp-aso__service">
            <div class="content__title">
                <span>我们的服务</span>
            </div>
            <div class="service__content">
                <div class="service__item" v-for="(item, index) in services" :key="index" :class="index === services.length - 1 ? 'service__row__item' : ''">
                    <div class="service__box service__row__box" v-if="index === services.length - 1">
                        <div class="row__box__left">
                            <div class="service__title">
                                <span>{{ item.title }}</span>
                            </div>
                            <div class="service__option">
                                <span>{{ item.content }}</span>
                            </div>
                        </div>
                        <div class="row__box__icon">
                            <div class="service__icon">
                                <el-image :src="item.cover" style="width: 80px;height: 80px;"></el-image>
                            </div>
                        </div>
                    </div>
                    <div class="service__box" v-else>
                        <div class="service__title">
                            <span>{{ item.title }}</span>
                        </div>
                        <div class="service__icon">
                            <el-image :src="item.cover" style="width: 80px;height: 80px;"></el-image>
                        </div>
                        <div class="service__option">
                            <span>{{ item.content }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="service__field">
            <div class="content__title">
                <span>服务领域</span>
            </div>
            <div class="field__content">
                <div class="sub__title">
                    <span>无门槛</span>
                </div>
                <div class="color__title">
                    <span>所有类型小程序都欢迎前来合作</span>
                </div>
                <div class="desc__title">
                    <span>不论您是什么样的小程序，均可获得可观流量</span>
                </div>
                <div class="area__list">
                    <div class="area__item" v-for="(item, index) in areas" :key="index">
                        <div class="area__icon">
                            <el-image :src="item.cover" style="width: 70px;height: 70px;"></el-image>
                        </div>
                        <div class="area__title">
                            <span>{{ item.title || '' }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="field__image">
                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/mpAso/industry__image.png'" style="width: 100%;height: auto;"></el-image>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getBanner } from '@/api/index'
export default {
    props: ['brings', 'services', 'areas'],
    data() {
        return {
            smallBanner: {}
        }
    },
    mounted() {
        this.getSmallBanner()
    },
    methods: {
        // 请求h5端banner
        getSmallBanner() {
            getBanner({code: 'h5-appletAso'})
            .then(response => {
                this.smallBanner = response.data[0]
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.aso__mobile {
    font-family: PingFangSC, PingFang SC;
}

.content__title {
    text-align: center;
    padding: 15px 10px 15px 10px;
    span {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 46px;
    }
}

.mp-aso__result {
    background: #E8EBFF;

    .result__item {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        min-height: 165px;
        padding: 5px 15px 15px 15px;
    }

    .result__box {
        .result__title {
            padding: 26px 20px 0 30px;
            span {
                font-size: 13px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 16px;
            }
        }
        .result__option__item {
            padding-left: 14px;
            padding-right: 14px;
            span {
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;
            }
        }
    }
}

.mp-aso__service {
    background: #F6F7FF;
}

.service__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .service__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .service__item {
        width: 50%;
        padding: 11px 13px 16px 13px;
    }
    .service__box {
        background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
        box-shadow: 0px 5px 30px 0px rgba(126,137,211,0.23);
        border-radius: 20px;
        padding: 24px 10px 15px 10px;
        text-align: center;
        .service__title {
            span {
                font-size: 13px;
                font-weight: 600;
                color: #333333;
                line-height: 24px;
            }
        }
        .service__option {
            padding: 8px 9px 16px 9px;
            text-align: center;
            span {
                font-size: 12px;
                font-weight: 400;
                color: #919AA5;
                line-height: 16px;
            }
        }
    }
    .service__item.service__row__item {
        width: 100%;
        .service__box.service__row__box {
            display: flex;
            align-items: center;
            .row__box__left {
                padding-right: 20px;
                padding-left: 20px;
            }
            .service__title {
                text-align: left;
                padding: 0;
            }
            .service__option {
                text-align: left;
                padding: 0;
            }
        }
    }
}

.service__field {
    background: #E8EBFF;
    padding: 0 20px 20px 20px;
    .field__content {
        background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
        box-shadow: 0px 5px 30px 0px rgb(126 137 211 / 13%);
        border-radius: 20px;
        padding: 20px 14px 25px 14px;
    }
    .sub__title {
        text-align: center;
        padding-bottom: 10px;
        span {
            font-size: 22px;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
        }
    }
    .color__title {
        text-align: center;
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/mpAso/title__background.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 2px;
        span {
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }
    }
    .desc__title {
        text-align: center;
        padding-top: 8px;
        padding-bottom: 10px;
        span {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 14px;
        }
    }

    .area__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .area__item {
            text-align: center;
            text-align: center;
            width: 25%;
            margin-bottom: 10px;
        }

        .area__title {
            span {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 16px;
            }
        }
    }
}
</style>