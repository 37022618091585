<template>
    <div class="cpd__mobile">
        <div class="cpd__mobile__banner">
            <el-link :underline="false" :href="smallBanner.link">
                <el-image :src="smallBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
        </div>

        <div class="why__cpd">
            <div class="content__title">
                <span>ASA广告投放服务</span>
            </div>
            <div class="content__sub__title">
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/sub__title__icon__left.png'" style="width: auto;height: 16px;"></el-image>
                <div class="desc"><span>高性价比托管式服务，无需额外增设投放人员，省时省力</span></div>
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/sub__title__icon__right.png'" style="width: auto;height: 16px;"></el-image>
            </div>
            <div class="why__content">
                <div class="why__item" v-for="(item, index) in reasons" :key="index">
                    <div class="why__box">
                        <div class="item__logo">
                            <el-image :src="item.cover" style="width: 36px;height: 36px;"></el-image>
                        </div>
                        <div class="item__right">
                            <div class="item__info">
                                <div class="item__title">
                                    <span>{{ item.title || '' }}</span>
                                </div>
                                <div class="item__option">
                                    <span>{{ item.content || '' }}</span>
                                </div>
                            </div>
                            <div class="item__consult">
                                <span>立即咨询</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="our__advantage">
            <div class="content__title">
                <span>我们的优势</span>
            </div>
            <div style="">
                <div class="left__icon" style="display: none;">
                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/cpd/advantage__left__background.png'" style="width: 182px;height: 342px;"></el-image>
                </div>
                <div class="advantage__content">
                <div class="advantage__item" v-for="(item, index) in advantages" :key="index">
                        <div class="advantage__box">
                            <div class="advantage__title">
                                <span>{{ item.title || '' }}</span>
                            </div>
                            <div class="advantage__option">
                                <span>{{ item.content || '' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { getBanner } from '@/api/index'
export default {
    props: ['reasons', 'advantages'],
    data() {
        return {
            smallBanner: {}
        }
    },
    mounted() {
        this.getSmallBanner()
    },
    methods: {
        // 请求h5端banner
        getSmallBanner() {
            getBanner({code: 'h5-cpd'})
            .then(response => {
                this.smallBanner = response.data[0]
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.cpd__mobile {
    font-family: PingFangSC, PingFang SC;
}

.content__title {
    text-align: center;
    padding: 15px 10px 15px 10px;
    span {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 46px;
    }
}

.content__sub__title {
    text-align: center;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    .desc {
        padding: 0 6px;
    }
    span {
        font-size: 12px;
        font-weight: 600;
        color: #6361BE;
        line-height: 22px;
    }
}

.why__cpd {
    background: #E8EBFF;
    .why__item {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/cpd/why__CPD__item__background.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 12px 14px 11px 14px;
    }
    .why__box {
        display: flex;
        align-items: center;
        padding-left: 20px;
        .item__logo {
            padding-right: 20px;
        }
        .item__right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 20px;
            padding-top: 20px;
            padding-right: 10px;
        }
        .item__title {
            padding-bottom: 2px;
            span {
                font-size: 13px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 20px;
            }
        }
        .item__option {
            width: 180px;
            span {
                font-size: 11px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 16px;
            }
        }
        .item__consult {
            background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/cpd/why__CPD__btn.png');
            width: 56px;
            height: 56px;
            background-size: 100% 100%;
            text-align: center;
            padding-top: 10px;
            span {
                display: inline-block;
                width: 24px;
                font-size: 12px;
                font-weight: 400;
                color: #B131BD;
                line-height: 16px;
            }
        }
    }
}

.our__advantage {
    background: #F6F7FF;
    .advantage__content {
       padding-bottom: 30px;
    }
    .advantage__item {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/bg_c_r.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: -20px;
    }
    .advantage__title {
        span {
            font-size: 13px;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
        }
    }
    .advantage__box {
        text-align: right;
        padding-right: 64px;
        padding-top: 20px;
        padding-bottom: 84px;
        margin-bottom: -30px;
    }
    .advantage__option {
        max-width: 220px;
        text-align: right;
        float: right;
        span {
            font-size: 12px;
            font-weight: 400;
            color: #878787;
            line-height: 24px;
        }
    }
}
</style>