import service from '@/utils/requrest'
const api = {
    doUploadsReady: 'api/uploads/ready',
}
/**
 * 请求数据
 * @param   object
 * @returns Promise
 */

export const doUploadsReady = (data) => {
    return service({
        method: 'post',
        url: api.doUploadsReady,
        data
    })
}
