<template>
  <div>
      <!-- <input id="file" ref="files" type="file" accept="image/*" @change="getImg" multiple="multiple" />
      <img :src="img_src"> -->
      <div style="display: flex;align-items: center;">
        <el-image v-if="img_src" :src="img_src" style="width: 80px;height: 80px;"></el-image>
        <span id="upload">
          <el-button type="primary" :loading="uploading">{{ txt }}</el-button>
          <input id="file" ref="files" type="file" accept="image/*" @change="getImg" multiple="multiple" />
        </span>
      </div>
  </div>
</template>
<script>
import {doUploadsReady} from '@/api/upload'
export default {
  data() {
    return {
      img_src: null,
      txt: '上传',
      uploading: false
    }
  },
  methods: {
    async getImg(e){
      this.uploading = true
      this.txt = '上传中'
      let files = e.target.files;
      for (let item of files) {
        let params = {
          size: item.size,
          model: 'image',
        }
        if(item.name) {
          params.name = item.name;
          params.path = item.path;
        }
        const result = await doUploadsReady(params);
        if(result) {
          const {data} = result;
          let formData = new FormData();
          formData.append('key', data.key);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.ossAccessKeyId);
          formData.append('signature', data.signature);
          formData.append('file', item);
          let xhr = new XMLHttpRequest();
          xhr.open('POST', data.host, true);
          xhr.onload = () => {
            if (xhr.status === 204) {
              this.img_src = data.filePath;
              this.uploading = false
              this.txt = '重新上传'
              this.$message.success('图片上传成功')
              this.$emit('imgPath', this.img_src)
            } else {
              this.uploading = false
              this.txt = '重新上传'
              this.$message.error('图片上传失败')
              // console.log('图片上传失败');
            }
          };
          xhr.send(formData);
        }
      }
    }
  }
}
</script>

<style scoped>
#upload{
		display: inline-block;
		margin-top:5px;
		position: relative;
		overflow: hidden;
}

#upbutton{
		/* width:100px;
		height:40px;
    border: none; */

}

#file {
  position: absolute;
		right: 0px;
		top: 0px;
		opacity: 0;
		-ms-filter: 'alpha(opacity=0)';
    cursor: pointer;
}
</style>