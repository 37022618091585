<template>
    <div class="news__detail">
        <div class="container">
            <div class="content__title">
                <span style="color: #333333;">{{ title || '' }}</span>
            </div>
            <div class="news__content">
                <div v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getArticle } from '@/api/index'
export default {
    data() {
        return {
            id: '',
            title: '',
            content: ''
        }
    },
    mounted() {
        this.id = this.$route.query.id
        console.log(this.id)
        this.getNewsDetail()
    },
    methods: {
        getNewsDetail() {
            getArticle({
                id: this.id,
            })
            .then(response => {
                this.title = response.data.title
                this.content = response.data.content
            })
        },
    },
}
</script>

<style lang="scss" scoped>

@media (max-width: 1023px) {
    .news__detail {
        padding: 30px 10px;
        margin-top: 110px;
    }
}

@media (min-width: 1024px) {
    .news__detail {
        padding: 60px 20px;
        margin-top: 0;
    }
}
</style>