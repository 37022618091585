<template>
    <div class="about">

        <div class="banner hidden-lg-and-down" style="width: 100%;margin-bottom: -6px;">
            <el-link :underline="false" :href="largeBanner.link" style="width: 100%">
                <el-image :src="largeBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
        </div>

        <div class="banner__mobile hidden-lg-and-up" style="width: 100%;margin-bottom: -8px;">
            <el-link :underline="false" :href="smallBanner.link">
                <el-image :src="smallBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
        </div>

        <div class="about_us">
            <div class="main">
                <div class="content_title">
                    <span>关于我们</span>
                </div>
                <div class="flex_row align_center" style="padding-bottom: 47px;">
                    <div class="company_banner">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/about/image__a__a.png'"></el-image>
                    </div>
                    <div class="company_desc">
                        <div v-html="about"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="join_us">
            <div class="main">
                <div class="content_title">
                    <span>加入我们</span>
                </div>
                <div class="offer_content">
                    <el-row class="flex_row align_center" style="height: 100%;">
                        <el-col :md="24" :lg="12">
                            <div class="offer_info">
                                <div v-html="join" style="font-size:13px;"></div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <div class="contact_us hidden__1199__down">
            <div class="main">
                <div class="content_title">
                    <span>联系我们</span>
                </div>
                <div class="contact_content">
                    <el-row class="flex_row align_center" style="height: 100%;">
                        <el-col :span="12">
                            <div class="company_logo">
                                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/about/logo__e__a.png'" style="width: 357px;height: 56px;"></el-image>
                            </div>
                            <div class="contact_list">
                                <div class="contact_item" v-for="(item, index) in company" :key="index">
                                    <div class="icon" v-if="item.name != 'working' && item.name != 'wxqrcode'">
                                        <el-image :src="item.img_path" class="icon__image"></el-image>
                                    </div>
                                    <div class="contact_view" v-if="item.name != 'working' && item.name != 'wxqrcode'">
                                        <span>{{ item.title || '' }}：{{ item.value || '' }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div v-for="(item, index) in company" :key="index" style="text-align: right;">
                                <el-image v-if="item.name === 'wxqrcode'" :src="item.value" style="width: auto;height: 320px;border-radius: 10px"></el-image>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getArticle, getBanner, getCompany } from '@/api/index'

export default {
    data() {
        return {    
            about: '',
            join: '',
            largeBanner: {},
            smallBanner: {},
            company: null
        }
    },
    mounted() {
    },
    created() {
        this.getAbout()
        this.getJoin()
        this.getBanners()
        this.getH5Banners()
        getCompany({})
        .then(response => {
            this.company = response.data
        })
    },
    methods: {
        // 请求banner
        getBanners() {
            getBanner({code: 'about'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        // 请求h5banner
        getH5Banners() {
            getBanner({code: 'h5-about'})
            .then(response => {
                this.smallBanner = response.data[0]
            })
        },

        // 请求关于我们
        getAbout() {
            getArticle({id: 1})
            .then(response => {
                this.about = response.data.content
            })
        },

        // 请求加入我们
        getJoin() {
            getArticle({id: 2})
            .then(response => {
                this.join = response.data.content
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.about {
    
}

.content_title {
    padding-top: 60px;
    padding-bottom: 30px;
    text-align: center;

    span {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
    }
}

@media screen and (min-width: 1200px) {
    .main {
        width: 1200px;
        margin: 0 auto;
    }

    .flex_row {
        display: flex;
    }

    .align_center {
        align-items: center;
    }
}

.about_us {
    background: #E8EBFF;
}
.company_banner {
    width: 100%;
    .el-image {
        width: 100%;
        max-width: 671px;
        height: auto;
    }
}
.company_desc {
    text-align: center;
    padding: 0 20px;
}
.desc_title {
    margin-bottom: 18px;
    span {
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px
     }
}
.desc_paragraph {
    margin-bottom: 18px;
    span {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 29px;
    }
}

@media screen and (min-width: 1200px) {
    .company_desc {        
        padding: 0;
        padding-left: 30px;
        text-align: left;
    }
}

.join_us {
    background: #F6F7FF;
}
.offer_content {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/news/about__backgorund.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; 
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 30px;
    padding-bottom: 160px;   
}
@media screen and (min-width: 1200px) {
    .join_us {
        .main {
            padding-bottom: 15px;
            width: 1263px;
        }
    }
    .offer_content {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/about/bg__b__a.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
    }

    .offer_title {
        margin-bottom: 16px;
        span {
            font-size: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
        }
    }

    .offer_item {
        margin-bottom: 16px;
    }

    .second_title {
        margin-bottom: 13px;

        span {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 16px;
        }
    }

    .offer_info {
        
        padding-left: 70px;
    }

    .list_item {
        span {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 23px;
        }
    }
}

.contact_us {
    background: #E8EBFF;
}

@media screen and (min-width: 1200px) {
    .contact_us {
        .main {
            width: 1260px;
            padding-bottom: 30px;
        }
    }

    .company_logo {
        margin-bottom: 25px;
    }

    .contact_content {
        background: #F6F7FF;
        padding: 30px;
        border-radius: 20px;
    }
    .contact_list {
        padding-bottom: 31px;
    }

    .contact_item {
        display: flex;
        margin-bottom: 20px;
        .icon {
            margin-right: 12px;
            .icon__image {
                width: auto;
                height: 20px;
            }
        }
        span {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
        }
    }
}

.nvkj_goal {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/bg_bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 78px;
    padding-bottom: 62px;

    h3 {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h6 {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
    }
}
</style>

<style>
.banner .el-link--inner, .banner__mobile .el-link--inner {
    width: 100%;
}
</style>