<template>
    <div class="home">
        <div class="search_cotent" :style="{backgroundImage: 'url('+largeBanner.img_src+')'}">
            <div class="main">
                <div class="search_content_detail">
                    <div class="search_content_box">
                        <div class="search_title">
                            <span>行业干货</span>
                        </div>
                        <div class="search_desc">
                            <span>聚焦应用市场情报分析、移动营销洞察、ASO推广干货内容</span>
                        </div>
                        <div class="form_search">
                            <div>
                                <el-input placeholder="请输入关键词" v-model="keyword" clearable>
                                    <div class="search__button" slot="suffix">
                                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/news/search.png'" style="width: 29px;height: 30px;"></el-image>
                                    </div>
                                </el-input>
                            </div>
                            <div style="display: flex;align-items: center;margin-top: 32px;" class="hidden-lg-and-down">
                                <div style="margin-right: 20px;">
                                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/news/hot.png'" style="width: 21px; height: 27px;"></el-image>
                                </div>
                                <div class="btn_cls btn_cls_1" v-for="(item, index) in classKeywords" :key="index" :class="index % 2 === 0 ? 'btn_cls_2' : 'btn_cls_1'">
                                    <span>{{ item.type }}</span>
                                </div>
                            </div>
                            <div class="hidden-lg-and-up">
                                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/news/hot.png'" style="width: 14px; height: 18px;"></el-image>
                                <div class="search__icon__small" v-for="(item, index) in classKeywords" :key="index" :class="index % 2 === 0 ? 'icon__small__2' : 'icon__small__1'">
                                    <span>{{ item.type || '' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="news__list__content">
            <div class="main">
                <div class="news__type__nav">
                    <div class="type__nav__item" :class="checkedNav === 'all' ? 'active' : ''" @click="changeNavType('all')">
                        <span>全部</span>
                    </div>
                    <div class="type__nav__item" :class="checkedNav === 'hot' ? 'active' : ''" @click="changeNavType('hot')">
                        <span>热门</span>
                    </div>
                    <div class="type__nav__item" :class="checkedNav === item.id ? 'active' : ''" v-for="(item, index) in navs" :key="index" @click="changeNavType(item.id)">
                        <span>{{ item.name || '' }}</span>
                    </div>
                </div>
                <div class="news__list">
                    <el-row v-if="news.length > 0">
                        <el-col :md="24" :lg="6" v-for="(item, index) in news" :key="index">
                            <div class="news__item" @click="toNewsDetail(item)">
                                <div class="news__box">
                                    <div class="news__image">
                                        <el-image :src="item.cover"></el-image>
                                    </div>
                                    <div class="news__detail">
                                        <div class="news__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="news__date-views__row">
                                            <div class="news_date">
                                                <span>2023/11/17</span>
                                            </div>
                                            <div class="news_views">
                                                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/news/view.png'" style="width: 15px;height: 10px;margin-right: 6px;"></el-image>
                                                <span>{{ item.view_num || 0 }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div v-else style="padding: 30px;text-align: center">
                        <span>暂无数据~</span>
                    </div>
                    <div class="news__pagination hidden__1199__down" style="padding: 20px;text-align: center;">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="pageInfo.page"
                            :page-sizes="[12, 24, 36, 48]"
                            :page-size="pageInfo.limit"
                            layout="sizes, prev, pager, next"
                            :total="total">
                            </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="nvkj_goal">
            <h3>用数据驱动APP用户持续增长</h3>
            <h6>13年移动广告经验，魔女累计为 15000 个APP带来 上亿 个有效新增用户</h6>
        </div> -->

    </div>
</template>

<script>
import { getList, getCategory, getBanner } from '@/api/index'

export default {
    data() {
        return {
            keyword: '',
            classKeywords: [
                {id:1, type: 'APP'},
                {id:2, type: '小程序'},
                {id:3, type: '5G'},
                {id:4, type: '高排名'},
                {id:5, type: '优质渠道'},
            ],
            navs: [],
            checkedNav: 'all',
            news: [],
            pageInfo: {
                page: 1,
                limit: 12,
                sort: 'sort',
                order: 'asc',
            },
            total: 0,
            largeBanner: {},
        }
    },
    mounted() {
        
    },
    created() {
        this.getNavs()
        this.getNewses()
        this.getLargeBanner()
    },

    methods: {
        // 请求pc端banner
        getLargeBanner() {
            getBanner({code: 'industry'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        toNewsDetail(item) {
            this.$router.push({path: '/news/detail', query: {id: item.id}})
        },
        
        changeNavType(type) {
            this.checkedNav = type
            this.getNewses()
        },

        getNavs() {
            getCategory({
                category_id: 19,
                page: 1,
                list_rows: 10000
            })
            .then(response => {
                this.navs = response.data
            })
        },

        getNewses() {
            const _param = {
                category_id: this.checkedNav,
                page: this.pageInfo.page,
                list_rows: this.pageInfo.limit
            }
            if (this.checkedNav === 'all') {
                _param.category_id = 19
            }
            if (this.checkedNav === 'hot') {
                _param.is_hot = 1
            }
            getList(_param)
            .then(response => {
                this.news = response.data.data
                this.total = response.data.total
            })
        },

        handleSizeChange(val) {
            this.pageInfo.limit = val
            this.getNewses()
        },

        handleCurrentChange(val) {
            this.pageInfo.page = val
            this.getNewses()
        }
    },
}
</script>

<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    background: #F4F4F4;
    font-family: PingFangSC, PingFang SC;
}

.btn_cls {
    padding: 3px 20px;
    border-radius: 15px;
    margin-right: 20px;
    box-shadow: inset 0px 0px 20px 0px rgba(255,255,255,0.26);
    span {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }
}
.btn_cls_1 {
    background: #8623C5;
}
.btn_cls_2 {
    background: #2D52F4;
}


@media screen and (min-width: 1200px) {
    .main {
        width: 1200px;
        margin: 0 auto;
    }

    .news__type__nav {
        justify-content: center;
    }

    /*  */
    .search_cotent {
        // background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/news/banner.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .search_cotent {
        height: 600px;
    }
    .search_content_detail {
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search_content_box {
        .search_title {
            margin-bottom: 30px;
            text-align: center;
            span {
                font-size: 56px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 56px;
            }
        }
        .search__button {
            padding: 10px 15px 9px 15px;
        }
        .search_desc {
            margin-bottom: 40px;
            text-align: center;
            span {
                font-size: 32px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 32px;
            }
        }
        .form_search {
            padding-top: 40px;
            max-width: 640px;
            margin: 0 auto;
        }
    }
    /* */
    .news__list__content {
        .news__type__nav {
            padding-top: 70px;
            padding-bottom: 40px;
        }
        .type__nav__item {
            padding-bottom: 10px;
            margin-right: 70px;
            position: relative;
            cursor: pointer;
            
            span {
                font-size: 24px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #666666;
                line-height: 24px;
                transition: all 0.3s;
            }
        }
        .type__nav__item:after {
            content: '';
            width: 48px;
            height: 4px;
            background: transparent;
            border-radius: 2px;
            position: absolute;
            left: -48px;
            bottom: 0;
            transition: all 0.3s;
        }
        .type__nav__item.active {
            span {
                color: #433FFF;
            }
        }
        .type__nav__item.active:after {
            background: #433FFF;
            left: calc(50% - 24px);
        }
        .news__item {
            padding: 0 10px 20px 10px;
        }
        .news__detail {
            background: #FFFFFF;
            margin-top: -5px;
            padding: 12px 20px 15px 15px;
            border-radius: 0 0 20px 20px;
            .news__title {
                padding-bottom: 20px;
            }
        }
    }
}

.news__list__content {
    background: #E8EBFF;
    .news__type__nav {
        display: flex;
        align-items: center;
    }
    .news__title {
        span {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 18px
        }
    }
    .news__date-views__row {
        span {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
        }
        .news_views {
            display: flex;
            align-items: center;
        }
    }
}

.news__date-views__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1199px) {
    .main {
        width: 100%;
    }
    /* */
    .search_cotent {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/news/news__banner.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .search_content_detail {
            padding: 70px 34px 18px 34px ;
        }
        .search_title {
            padding: 15px 10px 9px 10px;
            text-align: center;
            span {
                font-size: 24px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 36px;
            }
        }
        .search_desc {
            padding: 0 20px 15px 20px;
            text-align: center;
            span {
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
            }
        }
        .search__button {
            padding: 5px 15px;
        }
        .search__icon__small {
            display: inline-block;
            margin-left: 10px;
            margin-top: 10px;
            padding: 0 4px 2px 4px;
            text-align: left;
            border-radius: 6px;
            span {
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 16px;
            }
        }
        .search__icon__small.icon__small__1 {
            background: #2E2AFF;
        }
        .search__icon__small.icon__small__2 {
            background: #B606FF;
        }
    }
    /* */
    .news__list__content {
        padding: 20px;
        .news__type__nav {
            background: #FFFFFF;
            border-radius: 30px;
            margin-bottom: 20px;
            overflow-x: scroll;
        }
        .type__nav__item {
            min-width: 84px;
            text-align: center;
            padding: 8px 10px;
            span {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
            }
        }
        .type__nav__item.active {
            background: #433FFF;
            span {
                color: #FFFFFF;
            }
        }
        .news__box {
            background: #FFFFFF;
            box-shadow: 0px 11px 20px 0px rgba(0,0,0,0.07);
            border-radius: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .news__image {
                margin-bottom: -5px;
            }
            .el-image {
                width: 120px;
                height: auto;
                border-radius: 20px;
            }
            .news__detail {
                padding:  0 20px;
                width: 100%;
            }
            .news__title {
                // padding-bottom: 20px;
            }
        }
    }
    
}

.nvkj_goal {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/bg_bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 78px;
    padding-bottom: 62px;

    h3 {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h6 {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
    }
}

</style>

<style>
.form_search .el-input--suffix .el-input__inner {
    padding-left: 20px;
    padding-right: 60px;
    border-radius: 50px;
}

.news__pagination .el-pagination .btn-prev, .news__pagination .el-pagination .btn-next {
    margin-right: 6px;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    min-width: 32px;
}

.news__pagination .el-pager li {
    padding: 0;
    font-size: 13px;
    box-sizing: border-box;
    text-align: center;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    margin-right: 6px;
}

.news__pagination .el-pager li.active, .news__pagination .el-pager li:hover, .news__pagination .el-pager li:active, .news__pagination .el-pager li:focus {
    color: #FFFFFF;
    background: #6660E8;
}

.news__pagination .el-pagination .el-select .el-input .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
}

.el-select-dropdown__item.selected {
    color: #6660E8;
}
</style>