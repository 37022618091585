export function setStorage(key, val) {
    window.localStorage.setItem(key, val)
}

export function getStorage(key) {
    return window.localStorage.getItem(key)
}

export function removeStorage(key) {
    return window.localStorage.removeItem(key)
}

export function formatQueryParams (data) {
    let search = {}
    let need_params = {}
    let need_op = {}
    filter(data)

    function filter (data, ckey) {
        for (let key in data) {
            if (!data[key].hasOwnProperty('value')) {
                filter(data[key], key)
            } else {
                if (data[key]['value'] == undefined || data[key]['value'] == null) continue
                if (JSON.stringify(data[key]['value']) === '[]') continue

                if (ckey) {
                    need_params[`${ckey}.${key}`] = data[key].value
                    need_op[`${ckey}.${key}`] = data[key].op
                } else {
                    need_params[key] = data[key].value
                    need_op[key] = data[key].op
                }
            }
        }
    }

    search.filter = JSON.stringify(need_params)
    search.op = JSON.stringify(need_op)
    return search
}