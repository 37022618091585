<template>
    <div class="asa__mobile">
        <div class="asa__mobile__banner">
            <!-- <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/ASA__banner.png'" style="width: 100%;height: auto;"></el-image> -->
            <el-link :href="smallBanner.link" :underline="false">
                <el-image :src="smallBanner.img_src" style="width: 100%;height: auto;"></el-image>
            </el-link>
        </div>

        <div class="why__asa">
            <div class="content__title">
                <span>为什么要做ASA</span>
            </div>
            <div class="content__sub__title">
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/sub__title__icon__left.png'" style="width: auto;height: 16px;"></el-image>
                <div class="desc"><span>转化率高，价格低，ROI好</span></div>
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/sub__title__icon__right.png'" style="width: auto;height: 16px;"></el-image>
            </div>
            <div class="why__content">
                <div class="why__item" v-for="(item, index) in reasons" :key="index">
                    <div class="why__box">
                        <div class="item__title">
                            <span>{{ item.title || '' }}</span>
                        </div>
                        <div class="item__icon">
                            <el-image :src="item.cover" style="width: 80px;height: 80px;"></el-image>
                        </div>
                        <div class="item__option">
                            <span>{{ item.content || '' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="asa__service">
            <div class="content__title">
                <span>ASA广告投放服务</span>
            </div>
            <div class="content__sub__title">
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/sub__title__icon__left.png'" style="width: auto;height: 16px;"></el-image>
                <div class="desc"><span>高性价比托管式服务，无需额外增设投放人员，省时省力</span></div>
                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/asa/sub__title__icon__right.png'" style="width: auto;height: 16px;"></el-image>
            </div>
            <div class="service__content">
                <div class="service__item" v-for="(item, index) in asoOption" :key="index">
                    <div class="service__box" :style="{backgroundImage: 'url('+item.h5_background_img+')'}">
                        <div class="service__title">
                            <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_d_b.png'" style="width: 15px;height: 15px;margin-right: 8px;"></el-image>
                            <span>{{ item.title || '' }}</span>
                        </div>
                        <div class="service__option">
                            <span>{{ item.content || '' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mobile__cases">
            <div class="content__title">
                <span>优秀案例</span>
            </div>
            <div class="mobile_case_box" v-if="cases.length > 0">
                <div class="mobile_case_lists">
                    <div class="mobile_case_nav">
                        <div class="mobile_case_item_nav" :class="index === checkedCase ? 'active' : ''" v-for="(item, index) in cases" :key="index" @click="handleCaseChange(index)">
                            <div class="checked_ico"></div>
                            <div class="ico">
                                <el-image :src="item.cover"></el-image>
                            </div>
                        </div>
                    </div>
                    <div class="mobile_case_detail">
                        <div class="mobile_case_detail_title">
                            <span>{{ cases[checkedCase].title || '' }}</span>
                        </div>
                        <div class="mobile_case_detail_main">
                            <div v-html="cases[checkedCase].content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBanner } from '@/api/index'
export default {
    props: ['reasons', 'asoOption', 'cases'],
    data() {
        return {
            checkedCase: 0,
            smallBanner: {}
        }
    },
    mounted() {
        this.getSmallBanner()
    },
    methods: {
        // 请求h5端banner
        getSmallBanner() {
            getBanner({code: 'h5-asa'})
            .then(response => {
                this.smallBanner = response.data[0]
            })
        },

        handleCaseChange(index) {
            this.checkedCase = index
        },
    }
}
</script>

<style lang="scss" scoped>
.asa__mobile {
    font-family: PingFangSC, PingFang SC;
}

.content__title {
    text-align: center;
    padding: 15px 10px 15px 10px;
    span {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 46px;
    }
}

.content__sub__title {
    text-align: center;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    .desc {
        padding: 0 6px;
    }
    span {
        font-size: 12px;
        font-weight: 600;
        color: #6361BE;
        line-height: 22px;
    }
}

.why__asa {
    background: #E8EBFF;

    .why__content {
        display: flex;
        flex-wrap: wrap;
    }

    .why__item {
        width: 50%;
        padding: 12px 14px 18px 14px;
    }

    .why__box {
        background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
        border-radius: 20px;
        text-align: center;
    }

    .item__title {
        padding: 15px 10px 5px 10px;
        span {
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
        }
    }

    .item__icon {
        height: 80px;
    }

    .item__option {
        padding: 6px 6px 16px 6px;
        height: 94px;
        span {
            font-size: 12px;
            font-weight: 400;
            color: #919AA5;
            line-height: 16px;
        }
    }
}

.asa__service {
    background: #F6F7FF;
    .service__item {

    }
    .service__box {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        min-height: 120px;
        max-width: 365px;
        margin: 0 auto;
        .service__title {
            padding: 25px 20px 5px 45px;
            display: flex;
            align-items: center;
            span {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 28px;
            }
        }
        .service__option {
            padding-left: 68px;
            padding-right: 20px;
            padding-bottom: 30px;
            span {
                font-size: 13px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 22px;
            }
        }
    }
}

.mobile__cases {
        background: #E8EBFF;
        padding-bottom: 30px;
        .mobile_case_box {
            padding: 0 20px;
        }

        .mobile_case_lists {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 20px 10px;

            .mobile_case_nav {
                display: flex;
                align-items: center;
                margin-bottom: 32px;
            }
               
            .mobile_case_item_nav {
                margin-right: 50px;
                text-align: center;
                cursor: pointer;
                    
                .checked_ico {
                    width: 32px;
                    height: 4px;
                    background: transparent;
                    border-radius: 2px;
                    margin: 0 auto 15px auto;
                    transition: all 0.2s;
                }

                .ico {
                    width: 35px;
                    height: 35px;
                    border: 1px solid #A712B4;
                    border-radius: 10px;
                    transition: all 0.2s;
                }
            }

            .mobile_case_item_nav.active {
                .checked_ico {
                    background: #433FFF;
                }

                .ico {
                    width: 50px;
                    height: 50px;
                }
            }

            .mobile_case_detail_title {
                padding-bottom: 10px;
                span {
                    font-size: 14px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 14px;
                }
            }

        }
}

</style>