<template>
    <div class="head__content">
        <div class="head__row flex__row align__center">
            <div class="head__left flex__row align__center">
                <div class="head__logo">
                    <div class="logo">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/logo__user.png'" style="height: 40px; width: auto;"></el-image>
                    </div>
                </div>
                <div class="link__lists flex__row align__center">
                    <div class="link__item" :class="path === '/user/mission' ? 'active' : ''">
                        <el-link :underline="false" href="/user/mission">我的任务</el-link>
                    </div>
                    <div class="link__item" :class="path === '/user/detail' ? 'active' : ''">
                        <el-link :underline="false" href="/user/detail">收支明细</el-link>
                    </div>
                </div>
            </div>
            <div class="head__right">
                <div class="head__user__info flex__row align__center">
                    <div class="user__logo">
                        <el-image v-if="userInfor.head_img" :src="userInfor.head_img" style="width: 40px;height: 40px;"></el-image>
                        <div v-else style="width: 40px;height: 40px;background: #FFFFFF;border-radius: 50%;"></div>
                    </div>
                    <div class="user__name">
                        <span>{{ userInfor.nickname || '' }}</span>
                    </div>
                    <div class="log_out" @click="logOut">
                        <span>退出登录</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userInfo } from '@/api/user'
import { removeToken } from '@/utils/auth'

export default {
    data() {
        return {
            userInfor: {
                head_img: '',
                nickname: 'admin'
            },
            path: ''
        }
    },
    mounted() {
        this.path = this.$route.path
        this.getUserInfor()
    },
    methods: {
        // 获取用户信息
        getUserInfor() {
            userInfo()
                .then(response => {
                    // console.log(response)
                    const { head_img, nickname } = response.data
                    this.userInfor = { head_img, nickname }
                })
        },

        logOut() {
            removeToken()
            this.$router.push('/')
        },
    }
}
</script>

<style lang="scss" scoped>
.log_out {
    cursor: pointer;
    margin-left: 15px;
    span {
        font-size: 13px;
        color: #FFFFFF;
        line-height: 24px;
    }
}

.head__content {
    background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
    box-shadow: inset 0px 0px 30px 0px rgba(255,255,255,0.11);
}
.flex__row {
    display: flex;
}
.align__center {
    align-items: center;
}
.head__row {
    height: 70px;
    justify-content: space-between;
    padding: 0 40px;
}
.head__logo {
    margin-right: 100px;
}
.logo {
    // width: 132px;
    // height: 34px;
    // background: #fff;
}
.link__item {
    margin-right: 100px;
    position: relative;
    padding: 13px 0;
}

.link__item::after {
    content: '';
    width: 0;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
}

.link__item.active::after, .link__item:hover::after, .link__item:active::after, .link__item:focus::after {
    width: 24px;
    left: calc(50% - 12px);
}

.user__logo {
    border-radius: 50%;
    margin-right: 6px;
    background: #FFFFFF;
    width: 48px;
    height: 48px;
    text-align: center;
    padding-top: 4px;
}

.user__name {
    span {
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }
}
</style>

<style>
    .head__content .el-link--inner {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }
</style>