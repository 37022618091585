<template>
  <div>
      <input id="file" ref="files" type="file" accept="image/*" @change="getImg" multiple="multiple" />
      <img :src="img_src">
  </div>
</template>
<script>
import {doUploadsReady} from '@/api/upload'
export default {
  data() {
    return {
      img_src: null,
    }
  },
  methods: {
    async getImg(e){
      let files = e.target.files;
      for (let item of files) {
        let params = {
          size: item.size,
          model: 'image',
        }
        if(item.name) {
          params.name = item.name;
          params.path = item.path;
        }
        const result = await doUploadsReady(params);
        if(result) {
          const {data} = result;
          let formData = new FormData();
          formData.append('key', data.key);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.ossAccessKeyId);
          formData.append('signature', data.signature);
          formData.append('file', item);
          let xhr = new XMLHttpRequest();
          xhr.open('POST', data.host, true);
          xhr.onload = () => {
            if (xhr.status === 204) {
              this.img_src = data.filePath;
              console.log('图片上传成功');
              this.$emit('imgPath', this.img_src)
            } else {
              console.log('图片上传失败');
            }
          };
          xhr.send(formData);
        }
      }
    }
  }
}
</script>

<style scoped>
/* input[type='file'] {
    appearance:none;
    background-color: #c1c1c1;
    border: none;
    color: white;
    font-size: 16px;
    padding: 12px 14px;
} */
</style>