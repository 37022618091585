<template>
    <div >
        <div>测试</div>
    </div>
</template>

<script>
import { getArticle } from '@/api/index'
export default {
    data() {
        return {
            article: {
                title: '',
                content: '',
                clientWidth: 0
            },
        }
    },
    mounted() {
        this.onresize()
        window.addEventListener('resize', this.onresize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onresize)
    },
    async created() {
        let res = await getArticle({code:'product'})
        if (res) {
            if (this.clientWidth > 1200) {
                res.data.content = res.data.content.replace(new RegExp('<img', 'gi'), "<img style='width:auto;max-width:100%'")
            } else {
                res.data.content = res.data.content.replace(new RegExp('<img', 'gi'), "<img style='width:100%'")
            }
            this.article = res.data
        }
    },
    methods: {
        onresize() {
            this.clientWidth = document.documentElement.clientWidth
        },
    }
}
</script>

<style lang="scss" scoped>
.notice {
    min-height: 100%;
    margin-top: 92px;
}
</style>