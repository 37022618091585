import service from '@/utils/requrest'
const api = {
    getArticle: 'api/article/getArticle',
    getArticle1: 'api/index/getArticle1',
    getList: '/api/article/getList',
    getChild: '/api/article/getChild',
    getCategory: '/api/article/category',
    getBanner: '/api/banners/index',
    getCompany: '/api/article/getCompany',
    getBottom: '/api/article/getBottonData',
    getSelect: '/admin/devices.index/getSelect'
}
/**
 * 请求数据
 * @param   object
 * @returns Promise
 */

// 文章相关
export const getArticle = (params) => {
    return service({
        method: 'get',
        url: api.getArticle,
        params
    })
}

export const getArticle1 = (data) => {
    return service({
        method: 'post',
        url: api.getArticle1,
        data
    })
}

export const getList = (params) => {
    return service({
        method: 'get',
        url: api.getList,
        params
    })
}

export const getChild = (params) => {
    return service({
        method: 'get',
        url: api.getChild,
        params
    })
}

export const getCategory = (params) => {
    return service({
        method: 'get',
        url: api.getCategory,
        params
    })
}

export const getBanner = (params) => {
    return service({
        method: 'get',
        url: api.getBanner,
        params
    })
}

export const getCompany = (params) => {
    return service({
        method: 'get',
        url: api.getCompany,
        params
    })
}

export const getBottom = (params) => {
    return service({
        method: 'get',
        url: api.getBottom,
        params
    })
}

export const getSelect = (params) => {
    return service({
        method: 'get',
        url: api.getSelect,
        params
    })
}