<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
@import url('@/assets/css/common.scss');

#app{
  width: 100%;
  height: 100%;
}
</style>

<script>
import {getSysConfig} from '@/api/index';
export default {
  data() {
    return {

    }
  },
  async mounted() {
    //   let res = await getSysConfig()
    //   if (res.code === 200) {
    //     const {data} = res;
    //     let head = document.getElementsByTagName('head');
    //     let title1 = document.getElementsByTagName('title');
    //     let link = document.getElementsByTagName('link');
    //     let meta = document.createElement('meta')
    //     let meta1 = document.createElement('meta')
    //     meta.setAttribute('name', 'keywords');
    //     meta.setAttribute('content', data.www_keywords);
    //     meta1.setAttribute('name', 'description');
    //     meta1.setAttribute('content', data.www_desc);
    //     title1[0].innerText = data.www_title;
    //     link[0].setAttribute('href', data.www_ico);
    //     head[0].appendChild(meta)
    //     head[0].appendChild(meta1)
    // }

    // this.getSysConfigData();
  },

  metaInfo() {//nuxt.js中metaInfo==head
    console.log(this.extension);

    return {
      meta: [
        {
          hid: "keywords",
          name: 'keywords',
          content: this.extension.www_keywords
        },
        {
          hid: "description",
          name: 'description',
          content: this.extension.www_desc,
        }
      ]
    }
  },
  methods: {

  }
}
</script>
