<template>
    <div class="user__bottom__info flex__row align__center">
        <span>来米嗨皮（重庆）网络科技有限公司版权所有</span>
        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/icon__copyright.png'" style="width: 18px;height: 20px;margin-left: 8px;margin-right: 8px;"></el-image>
        <span @click="goBeian">渝ICP备2023016265号-1</span>
    </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    goBeian() {
      window.open('https://beian.miit.gov.cn/');
    },
  }
}
</script>
<style lang="scss" scoped>
.user__bottom__info {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/user__bottom__bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 34px 20px 33px 20px;
    text-align: center;
    justify-content: center;

    span {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }
}
</style>