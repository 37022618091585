<template>
    <div class="page__header" :style="{background: show ? '#FFFFFF' : '', position: show ? 'fixed' : ''}" :class="path === '/news/detail' ? 'detail__page__header' : ''">
        <div class="main">
            <div class="header__content flex__row align__center justify__between" v-if="!show">
                <div class="page__logo">
                    <el-link :underline="false" href="/">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/header__logo.png'" style="width: 168px; height: auto;"></el-image>
                    </el-link>
                </div>
                <div class="header__nav">
                    <div class="header__nav__list flex__row align__center">
                        <div
                            v-for="(item, index) in links"
                            :key="index" 
                            class="header__nav__item" 
                            :class="path === item.path ? 'active' : ''">
                            <el-link :underline="false" :href="item.path">{{ item.name }}</el-link>
                        </div>
                        <div class="header__nav__item has__chaildren" v-if="isLogin">
                            <el-link :underline="false">用户中心<i class="el-icon-arrow-down el-icon--right"></i></el-link>
                            <ul class="children__list">
                                <li v-for="(item, index) in userLinks" :key="index">
                                    <el-link :underline="false" :href="item.path">{{ item.name }}</el-link>
                                </li>
                                <li style="margin-bottom: 20px;">
                                    <el-link :underline="false" @click="logOut">退出登录</el-link>
                                </li>
                            </ul>
                        </div>
                        <div class="header__nav__item" v-else>
                            <el-link :underline="false" href="/login">登录 / 注册</el-link>
                        </div>
                    </div>
                </div>
                <div class="nav__list__button" @click="openMobileNav">
                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/icon__nav__open.png'" style="width: 32px;height: 32px;"></el-image>
                </div>
            </div>
        </div>
        <div class="mobile__header__content" v-if="show">
            <div class="flex__row align__center justify__between" style="height: 70px;">
                <div class="mobile__header__logo">
                    <el-link :underline="false" href="/">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/mobile__header__open__logo.png'" style="width: 140px;height: auto;"></el-image>
                    </el-link>
                </div>
                <div class="mobile__close__header" @click="closeMobileNav">
                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/close%402x.png'" style="width: 32px;height: 32px;"></el-image>
                </div>
            </div>
            <div class="mobile__header__nav">
                <div
                    v-for="(item, index) in links"
                    :key="index" 
                    class="mobile__header__nav__item" 
                    :class="path === item.path ? 'active' : ''">
                    <el-link :underline="false" :href="item.path">{{ item.name }}</el-link>
                </div>
                <!-- <template v-if="isLogin">
                    <div class="mobile__header__nav__item" v-for="item in userLinks" :key="item.id">
                        <el-link :underline="false" :href="item.path">{{ item.name }}</el-link>
                    </div>
                    <div class="mobile__header__nav__item">
                        <el-link :underline="false" @click="logOut">退出登录</el-link>
                    </div>
                </template>
                <div class="mobile__header__nav__item" v-else>
                    <el-link :underline="false" href="/login">登录 / 注册</el-link>
                </div> -->
            </div>
            <!-- <div class="bottom__consult__button">
                <span>免费咨询</span>
            </div> -->
        </div>
    </div>
</template>

<script>
import { getToken, removeToken } from '@/utils/auth'

export default {
    data() {
        return {
            clientWidth: 0,
            blod: false,
            path: '',
            show: false,
            isLogin: false,
            links: [
                {name: '首页', path: '/'},
                {name: 'ASO优化', path: '/aso'},
                {name: 'ASA服务', path: '/asa'},
                {name: 'CPD服务', path: '/cpd'},
                {name: '小程序ASO', path: '/mpAso'},
                {name: '行业干货', path: '/news'},
                {name: '关于我们', path: '/home/about'}
            ],
            userLinks: [
                {id: 10086, name: '我的任务', path: '/user/mission'},
                {id: 10010, name: '收支明细', path: '/user/detail'},
            ]
        }
    },
    mounted() {
        this.path = this.$route.path
        this.isLogin = getToken() ? true : false
    },
    methods: {
        openMobileNav() {
            this.show = true
        },
        closeMobileNav() {
            this.show = false
        },
        logOut() {
            removeToken()
            location.reload()
        },
    }
}
</script>

<style lang="scss">
.page__logo .el-link--inner {
    height: 48px;
}

.children__list {
    position: absolute;
    z-index: 10003;
    background: #0D032A;
    left: 0;
    padding: 0 20px;
    display: none;
    li {
        margin-top: 20px;
    }
}

.has__chaildren:hover  {
    .children__list {
        display: block;
    }
}

.mobile__header__nav {
    max-height: 320px;
    overflow: scroll;
}

.mobile__header__nav__item {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    span {
        font-size: 17px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
    }
}

.mobile__header__nav__item::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 10px);
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background: #FFFFFF;
}

.mobile__header__nav__item.active {
    span {
        font-weight: bold;
        color: #433FFF;
    }
}

.mobile__header__nav__item.active::after {
    background: #433FFF;
}
</style>

<style lang="scss" scoped>
.header__nav {
    display: none;
}

@media (max-width: 1023px) {
    .page__header {
        background: transparent;
        padding: 0 20px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100%;
    }

    .page__header.detail__page__header {
        background: #0D032A;
    }

    .header__content {
        height: 70px;
    }

    .mobile__header__nav__item {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        span {
            font-size: 17px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;
        }
    }

    .bottom__consult__button {
        background: #FFFFFF;
        position: fixed;
        z-index: 99999;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 18px 20px;

        span {
            display: block;
            text-align: center;
            background: linear-gradient(270deg, #A712B4 0%, #2456F8 100%);
            box-shadow: 0px 5px 10px 0px rgba(126,85,221,0.4), inset 0px 0px 30px 0px rgba(255,255,255,0.61);
            border-radius: 45px;
            line-height: 40px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
        }
    }
}

@media screen and (min-width: 1024px) {
    .page__header {
        // background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/home/background__header__pc.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        background: #0D032A;
    }
    .main {
        max-width: 1200px;
        margin: 0 auto;
    }
    .nav__list__button {
        display: none;
    }
    .header__content {
        height: 70px;
    }

    .header__nav {
        display: flex;
        .header__nav__item {
            padding: 0 20px;
            position: relative;
        }
        .header__nav__item::after {
            content: '';
            width: 24px;
            height: 4px;
            border-radius: 2px;
            background: #FFFFFF;
            position: absolute;
            bottom: -8px;
            left: -24px;
            opacity: 0;
            transition: all 0.2s;
        }
        .header__nav__item:hover::after, .header__nav__item:active::after, .header__nav__item:focus::after, .header__nav__item.active::after {
            // left: 20px;
            left: calc(50% - 12px);
            opacity: 1;
        }
    }
}
</style>

<style>
@media screen and (min-width: 1200px) {
    .header__nav__item .el-link--inner {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 16px;
    }
}


/* .header__nav__item .el-link--inner:hover, .header__nav__item .el-link--inner:focus, .header__nav__item .el-link--inner:active {

} */
</style>