import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import router from './router'
import store from './store'


import '@/permission' 

import 'element-ui/lib/theme-chalk/display.css';
Vue.config.productionTip = false

import 'hover.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import * as globalMethod from '@/utils/methods'

import './assets/css/resite.css'
import request from '@/utils/requrest'
Vue.prototype.request = request
Object.keys(globalMethod).forEach(key => {
  Vue.prototype[key] = globalMethod[key]
})
Vue.use(ElementUI);
// Vue.use(Meta);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
