<template>
    <div class="aso">
        <div class="top__banner">
            <div class="hidden__1199__down show__1200__up large__banner" :style="{backgroundImage: 'url('+largeBanner.img_src+')'}">
                <div class="flex__row align__center" style="height: 600px;">
                    <div class="container">
                        <div class="banner__content">
                            <h1 class="large__title">
                                ASO全案营销专家
                            </h1>
                            <h5 class="small__desc">
                                助力APP高性价比获取全球优质客户
                            </h5>
                            <el-link :href="largeBanner.link" :underline="false">
                                <div class="content__button color__button"  @click="consultNow">
                                    <span>立即咨询</span>
                                </div>  
                            </el-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up small__banner">
                <!-- <el-image :src="'http://monvkeji.oss-cn-chengdu.aliyuncs.com/integral-wall/mobile/home/home__banner.png'" style="width:100%;height:auto;"></el-image> -->
                <el-image :src="smallBanner.img_src" style="width:100%;height:auto;"></el-image>
                <div class="consult__button">
                    <el-link :href="smallBanner.link" :underline="false">
                        <div class="white__button" @click="consultNow">
                            <span>立即咨询</span>
                        </div>  
                    </el-link>
                </div>
            </div>
        </div>

        <div class="aso__reason">
            <div class="hidden__1199__down show__1200__up slider__content__large aso__reason__large">
                <div class="container">
                    <h1 class="content__title" style="color: #333333;">为什么要做ASO</h1>
                    <div class="slider__content__content">
                        <div class="slider__content__item aso__reason__item" v-for="(item, index) in reasons" :key="index">
                            <div class="slider__content__box">
                                <div class="slider__content__title slider__content__title_1">
                                    <span>{{ item.title || '' }}</span>
                                </div>
                                <div class="slider__content__icon">
                                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/icon_a_1.png'" class="icon__image"></el-image>
                                </div>
                                <div class="slider__content__title slider__content__title_2">
                                    <span>{{ item.title || '' }}</span>
                                </div>
                                <div class="slider__content__desc">
                                    <span>{{ item.content || '' }}</span>
                                </div>
                                <div class="slider__content__button" @click="consultNow">
                                    <span>立即咨询</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up slider__content__small aso__reason__small">
                <h1 class="content__title" style="color: #333333;">为什么要做ASO</h1>
                <div class="slider__content__content">
                    <div class="slider__content__item aso__reason__item" v-for="(item, index) in reasons" :key="index" :class="index === reasons.length - 1 ? 'slider__content__item__row' : ''">
                        <div class="slider__content__box" v-if="index === reasons.length - 1">
                            <div class="flex__row align__center">
                                <div>
                                    <div class="slider__content__title">
                                        <span>{{ item.title || '' }}</span>
                                    </div>
                                    
                                    <div class="slider__content__desc">
                                        <span>{{ item.content || '' }}</span>
                                    </div>
                                </div>
                                <div class="slider__content__icon">
                                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/icon_a_1.png'" class="icon__image"></el-image>
                                </div>
                            </div>
                        </div>
                        <div class="slider__content__box" v-else>
                            <div class="slider__content__title">
                                <span>{{ item.title || '' }}</span>
                            </div>
                            <div class="slider__content__icon">
                                <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/icon_a_1.png'" class="icon__image"></el-image>
                            </div>
                            <div class="slider__content__desc">
                                <span>{{ item.content || '' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="aso__option">
            <div class="hidden__1199__down show__1200__up large__option">
                <div class="container">
                    <h1 class="content__title" style="color: #333333;">ASO怎么做</h1>
                    <el-row>
                        <el-col :lg="12" :md="24" :xs="24" v-for="(item, index) in options" :key="index">
                            <div class="option__item" @mouseenter="changeActiveInd(index)" :style="{backgroundImage: activeInd === index ? 'url('+item.selected_background_img+')' : 'url('+item.select_background_img+')'}">
                                <div class="option__box" :class="activeInd === index ? 'hover__option__box' : ''">
                                    <div class="flex__row align__center" style="height: 100%;justify-content: space-between;">
                                        <div class="option__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="option__list">
                                            <div class="option__list__item" v-for="(option, ort) in item.content" :key="ort">
                                                <i class="el-icon-check"></i>
                                                <span>{{ option }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up small__option">
                <h1 class="content__title" style="color: #333333;">ASO怎么做</h1>
                <el-row>
                    <el-col :lg="12" :md="24" :xs="24" v-for="(item, index) in options" :key="index">
                        <div class="option__item" :style="{backgroundImage: 'url('+item.h5_background_img+')'}">
                            <div class="option__box" >
                                <div class="flex__row align__center" style="height: 100%;">
                                    <div class="option__title">
                                        <span>{{ item.title || '' }}</span>
                                    </div>
                                    <div class="option__list">
                                        <div class="option__list__item" v-for="(option, ort) in item.content" :key="ort">
                                            <i class="el-icon-check"></i>
                                            <span>{{ option }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="our__advantage">
            <div class="hidden__1199__down show__1200__up our__advantage__large">
                <div class="container">
                    <div class="row__content">
                        <h1 class="content__title" style="color: #333333;">我们的优势</h1>
                    </div>
                    <div class="content__main">
                        <div class="flex__row collapse__box">
                            <div class="collapse__item" v-for="(item, index) in advantages" :key="index">
                                <div class="collapse__item__box">
                                    <div class="collapse__item__cover" style="width: 214px;height: 498px;padding: 66px 33px 30px 0;"
                                        :style="{backgroundImage: 'url('+item.select_background_img+')'}">
                                        <div class="collapse__item__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="collapse__item__sub__title">
                                            <span>{{ item.short_content || '' }}</span>
                                        </div>
                                    </div>
                                    <div class="collapse__item__open" style="width: 306px;height: 438px;">
                                        <div class="collapse__item__title">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="collapse__item__sub__title">
                                            <span>{{ item.short_content || '' }}</span>
                                        </div>
                                        <div class="collapse__item__desc">
                                            <span>{{ item.content || '' }}</span>
                                        </div>
                                        <div class="collapse__item__image">
                                            <el-image :src="item.selected_background_img" style="width: 200px;height: auto;"></el-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show__1199__down hidden__1200__up our__advantage__small">
                <h1 class="content__title" style="color: #333333">
                    我们的优势
                </h1>
                <el-carousel trigger="click" arrow="never" type="card"  height="240px" :autoplay="false">
                    <el-carousel-item v-for="(item, index) in advantages" :key="index" >
                        <div style="padding: 0 10px;">
                            <div class="advantage__small__box">
                                <div class="advantage__small__title">
                                    <span>{{ item.title || '' }}</span>
                                </div>
                                <div class="advantage__small__sub__title">
                                    <span>{{ item.short_content || '' }}</span>
                                </div>
                                <div class="advantage__small__desc">
                                    <span>{{ item.content || '' }}</span>
                                </div>
                                <div class="advantage__small__logo">
                                    <el-image :src="item.h5_background_img" class="icon__logo"></el-image>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { getList, getBanner } from '@/api/index'
import { consult } from '@/assets/consult'

export default {
    data() {
        return {
            reasons: [],
            options: [],
            advantages: [],
            activeInd: -1,
            largeBanner: {},
            smallBanner: {}
        }
    },
    created() {
        this.getReasons()
        this.getOptions()
        this.getAdvantages()
        this.getLargeBanner()
        this.getSmallBanner()
    },
    methods: {
        consultNow() {
            consult()
        },

        // 请求pc端banner
        getLargeBanner() {
            getBanner({code: 'aso'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        // 请求h5端banner
        getSmallBanner() {
            getBanner({code: 'h5-aso'})
            .then(response => {
                this.smallBanner = response.data[0]
            })
        },

        // 请求为什么要做ASO
        getReasons() {
            getList({
                category_id: 8,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.reasons = response.data.data
            })
        },

        // 请求ASO怎么做
        getOptions() {
            getList({
                category_id: 7,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.options = response.data.data
                for (let i = 0; i < this.options.length; i ++) {
                    this.options[i].content = JSON.parse(this.options[i].content_json)
                }
            })
        },

        // 请求我们的优势
        getAdvantages() {
            getList({
                category_id: 6,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.advantages = response.data.data
            })
        },

        changeActiveInd(index) {
            this.activeInd  = index
        }
    },
}
</script>

<style lang="scss" scoped>
.top__banner {
    .large__banner {
        // background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/bg_top.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 600px;
    }

    .small__banner {
        margin-bottom: -6px;
        position: relative;
        .consult__button {
            position: absolute;
            bottom: 210px;
            left: 40px;
            z-index: 10001;
        }
    }
}

.aso__reason {
    background: #E8EBFF;
}

.aso__reason__large {
    padding: 60px 20px;

    .aso__reason__item {
        width: 373px;
    }
}

.aso__reason__small {
    padding: 15px 0;
}

.our__advantage {
    .our__advantage__large {
        background: #E8EBFF;

       .collapse__item__box {
            width: 214px;
            height: 498px;
       }
       .collapse__item__desc {
            padding-bottom: 28px;
       }

       .collapse__item__open {
            background: #F5F6FF;
            border-radius: 20px;
            top: 25px;
       }

       .collapse__item__box:hover {
            width: 306px;

            .collapse__item__cover {
                left: -214px;
            }

            .collapse__item__open {
                opacity: 1;
            }
       }
    }

    .our__advantage__small {
        background: #E8EBFF;
        padding: 15px 0;
        .advantage__small__box {
            background: #FFFFFF;
            padding: 24px 12px 6px 20px;
            border-radius: 10px;
        }

        .advantage__small__logo {
            padding: 0;
            .icon__logo {
                width: 80px;
                height: 80px;
            }
        }

        .advantage__small__title {
            padding-bottom: 10px;
            position: relative;
            span {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 16px;
            }
        }

        .advantage__small__title::after {
            content: '';
            display: block;
            width: 32px;
            height: 4px;    
            border-radius: 2px;
            background: #181818;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .advantage__small__sub__title {
            padding-top: 6px;
            padding-bottom: 6px;
            span {
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 12px;
            }
        }

        .advantage__small__desc {
            padding-bottom: 14px;
            span {
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 16px;
            }
        }
    }
}

.aso__option {
    .large__option {
        background: #F6F7FF;
        padding: 60px 20px;

        .option__item {
            height: 330px;
            background-size: 100% 100%;
            padding: 25px 30px 15px 30px;
            cursor: pointer;
            transition: all 0.2s;
        }

        .option__box {
            height: 100%;
            padding-left: 52px;
            padding-right: 45px;
        }

        .option__title {
            width: 115px;
            padding-bottom: 16px;
            position: relative;
            text-align: center;
            span {
                font-size: 28px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #181818;
                line-height: 32px;
            }
        }

        .option__title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(50% - 16px);
            width: 32px;
            height: 4px;
            background: #181818;
            border-radius: 2px;
        }

        .option__list__item {
            i {
                margin-right: 6px;
                color: #333333;
            }

            span {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 30px;
            }
        }

        .hover__option__box {
            .option__title {
                span {
                    color: #FFFFFF;
                }
            }

            .option__title::after {
                background: #FFFFFF;
            }

            .option__list__item {
                i {
                    color: #FFFFFF;
                }

                span {
                    color: #FFFFFF;
                }
            }
        }
    }

    .small__option {
        background: #F6F7FF;
        padding: 15px 10px;

        .option__item {
            height: 125px;
            background-size: 100% 100%;
            padding: 12px 15px 17px 15px;
        }

        .option__box {
            height: 100%;
            padding-left: 25px;
            padding-right: 10px;
        }

        .option__title {
            width: 60px;
            padding-bottom: 16px;
            position: relative;
            text-align: center;
            span {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #181818;
                line-height: 16px;
            }
        }

        .option__title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(50% - 16px);
            width: 32px;
            height: 4px;
            background: #181818;
            border-radius: 2px;
        }

        .option__list {
            padding-left: 30px;
        }

        .option__list__item {
            i {
                margin-right: 6px;
                color: #333333;
            }

            span {
                font-size: 13px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 16px;
            }
        }
    }
}
</style>

<style lang="scss">
.slider__content__large {
    .slider__content__content {
        display: flex;
        justify-content: center;
    }

    .slider__content__item {
        padding: 22px 15px 32px 15px;
    }

    .slider__content__box {
        padding: 50px 30px;
        background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
        border-radius: 20px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(232, 235, 255, 1), rgba(232, 235, 255, 0), rgba(232, 235, 255, 1)) 1 1;
        text-align: center;
        transition: all 0.3s;
        height: 400px;
        overflow: hidden;

        .slider__content__title {
            margin-bottom: 32px;

            span {
                    font-size: 28px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
            }
        }

        .icon__image {
            width: 160px;
            height: 160px;
        }

        .slider__content__title_2 {
            display: none;
            margin-bottom: 15px;
        }

        .slider__content__button {
            display: none;
            border-radius: 24px;
            margin-top: 25px;
            background: #fff;
            padding: 15px 0;
            span {
                font-size: 18px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #5D3CDC;
                line-height: 18px;
            }
        }

        .slider__content__button:hover, .slider__content__button:focus, .slider__content__button:active {
            cursor: pointer;
        }

        .slider__content__desc {
            margin-top: 20px;

            span {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #919AA5;
                line-height: 22px;
            }
        }
    }

    .slider__content__box:hover, .slider__content__box:focus, .slider__content__box:active {
        background: linear-gradient(180deg, #A712B4 0%, #2456F8 100%);
        padding-top: 20px;
        padding-bottom: 33px;
        .slider__content__title_1 {
            display: none;
        }
        .slider__content__title_2 {
            display: block;
            margin-bottom: 15px;
        }
        .slider__content__button {
            display: block;
        }
        .slider__content__title {
            span {
                color: #FFFFFF;
            }
        }

        .slider__content__desc {
            span {
                color: #FFFFFF;
            }
        }
    }
}

.slider__content__small {
    .slider__content__content {
        display: flex;
        flex-wrap: wrap;
    }

    .slider__content__item {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .slider__content__item.slider__content__item__row {
        width: 100%;
        .slider__content__box {
            text-align: left;
            padding-left: 80px;
            .slider__content__icon {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .slider__content__box {
        background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
        border-radius: 20px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(232, 235, 255, 1), rgba(232, 235, 255, 0), rgba(232, 235, 255, 1)) 1 1;
        text-align: center;
        padding: 24px 6px 16px 6px;
    }

    .slider__content__title {
        padding-bottom: 15;
        span {
            font-size: 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 15px;
        }
    }

    .slider__content__icon {
        padding-bottom: 8px;
        .icon__image {
            width: 80px;
            height: 80px;
        }
    }

    .slider__content__desc {
        height: 72px;
        span {
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #919AA5;
            line-height: 16px;
        }
    }
}
</style>