<template>
    <div class="user__page">
        <div class="user__head">
            <user-top></user-top>
        </div>
        <div class="user__main">
            <router-view></router-view>
        </div>
        <div class="user__bottom">
            <user-bottom></user-bottom>
        </div>
    </div>
</template>

<script>
import userTop from './userTop.vue'
import userBottom from './userBottom.vue'

export default {
    components: {
        userTop,
        userBottom
    }
}
</script>

<style lang="scss" scoped>
.user__page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .user__head{
        // width: 100%;
        // height: 100%;
    }
    .user__main{
        width: 100%;
        height: 100%;
    }
    .user__bottom{
        // height: 100%;
    }
} 
</style>