<template>
  <div class="article">
    <div class="container">
      <div class="content__title">
        <span style="color: #333333;">{{ title || '' }}</span>
      </div>
      <div class="news__content">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getDataByCode} from "@/api/user";

  export default {
    data() {
      return {
        code: null,
        title: null,
        content: null
      }
    },
    mounted() {
      this.code = this.$route.query.code
      this.getArticle()
    },
    methods: {
      getArticle() {
        const code = this.code
        getDataByCode({code})
            .then(res => {
              this.content = res.data.content
              this.title = code === 'agreement' ? '用户协议' : '隐私政策'
            })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @media (max-width: 1023px) {
    .article {
      padding: 30px 10px;
    }
  }

  @media (min-width: 1024px) {
    .article {
      padding: 60px 20px;
      margin-top: 0;
    }
  }
</style>