import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout/Layout.vue'
import userLayout from '@/views/user/layout/userLayout.vue'
Vue.use(VueRouter)

const routes = [
  //重定向
  // {
  //   path: "/",
  //   redirect: '/home'
  // },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        component: () => import('@/views/home/index.vue')
      },
      {
        path: "/home/about",
        component: () => import('@/views/home/about.vue')
      },
    ]
  },
  {
    path: "/test",
    component: Layout,
    children: [
      {
        path: "/test",
        component: () => import('@/views/test/index.vue')
      },
    ]
  },
  {
    path: "/user",
    component: userLayout,
    children: [
      {
        path: "/user/detail",
        component: () => import('@/views/user/detail.vue'),
        meta: { requireAuth: true }
      },
      {
        path: "/user/mission",
        component: () => import('@/views/user/mission.vue')
      },
    ]
  },
  {
    path: "/product/index",
    component: Layout,
    children: [
      {
        path: "/product/index",
        component: () => import('@/views/product/index.vue')
      },
    ]
  },
  {
    path: "/aso",
    component: Layout,
    children: [
      {
        path: "/aso",
        component: () => import('@/views/aso/index.vue')
      },
    ]
  },
  {
    path: "/asa",
    component: Layout,
    children: [
      {
        path: "/asa",
        component: () => import('@/views/asa/index.vue')
      },
    ]
  },
  {
    path: "/cpd",
    component: Layout,
    children: [
      {
        path: "/cpd",
        component: () => import('@/views/cpd/index.vue')
      },
    ]
  },
  {
    path: "/mpAso",
    component: Layout,
    children: [
      {
        path: "/mpAso",
        component: () => import('@/views/mpAso/index.vue')
      },
    ]
  },
  {
    path: "/news",
    component: Layout,
    children: [
      {
        path: "/news",
        component: () => import('@/views/news/index.vue')
      },
      {
        path: "/news/detail",
        component: () => import('@/views/news/detail.vue')
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/article',
    component: () => import('@/views/article/index.vue')
  },
  {
    path:"*",
    component:()=>import('@/views/notFound/notFount.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
