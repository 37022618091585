<template>
    <div class="left-to-right-upfold">
        <ul class="card-list">
            <li v-for="(item, index) in list" :key="index" class="card-item" :style="{width: width + 'px',height: height+'px'}">
                <h3 class="card-title card-txt">{{ item.title || '' }}</h3>
                <h4 class="card-short_content card-txt">{{ item.short_content || '' }}</h4>
                <h5 class="card-content card-txt">{{ item.content || '' }}</h5>
            </li>
        </ul>
    </div>
</template>

<script>

/* **
    * 从左至右展开组件
    * leftToRightUnfold
    * 
    * @property {Array}         list                                展示的列表数组（数组元素为对象，title-标题；short_content-简短介绍；content-完整介绍；select_background_img-图标）
    * @property {Number}        width                               item未展开时的宽度
    * @property {Number}        height                              item的高度
    * @property {Number}        hoverWidth                          展开时的宽度    
** */

export default {
    props: {
        list: {
            type: Array,
            required: true,
        },
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        hoverWidth: {
            type: Number,
            required: true
        },
        titleStyle: {
            type: Object,
            default: {}
        },
        shortStyle: {
            type: Object,
            default: {}
        },
        contentStyle: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>

<style scoped lang="scss">
.card-list {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;

    .card-item {
        overflow: hidden;
        position: relative;
        box-shadow: 0 4px 20px 1px rgb(19 21 69 / 10%);
        opacity: 1;
        padding: 40px;
        box-sizing: border-box;
        transition: all 0.24s cubic-bezier(0.645,0.045,0.355,1);

        .card-title {
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 24px;
            color: hsla(0, 0%, 100%, 0.9);
        }

        .card-short_content {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 8px;
            color: hsla(0, 0%, 100%, 0.9);
        }

        .card-content {
            font-size: 14px;
            line-height: 16px;
            color: hsla(0,0%,100%,.7);
        }

        .card-txt {
            display: inline-block;
            white-space: nowrap;
            font-weight: 500;
            transition: all 0.4s cubic-bezier(0.645,0.045,0.355,1);
        }
    }

    .card-item:not(:last-of-type) {
        margin-right: 30px;
    }

    .card-item:first-child {
        background: #8525ff;
    }

    .card-item:nth-child(2) {
        background: #9423ff;
    }

    .card-item:nth-child(3) {
        background: #ad26ff;
    }

    .card-item:nth-child(4) {
        background: #d01feb;
    }

    .card-item:nth-child(5) {
        background: #e538bf;
    }

    .card-item:nth-child(6) {
        background: #982aff;
    }

    .card-item::before,  .card-item::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
        border-radius: 8px;
        transition: all 0.2s cubic-bezier(0.645,0.045,0.355,1);
    }
}


</style>
