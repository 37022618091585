import { render, staticRenderFns } from "./about.vue?vue&type=template&id=c9a047de&scoped=true"
import script from "./about.vue?vue&type=script&lang=js"
export * from "./about.vue?vue&type=script&lang=js"
import style0 from "./about.vue?vue&type=style&index=0&id=c9a047de&prod&lang=scss&scoped=true"
import style1 from "./about.vue?vue&type=style&index=1&id=c9a047de&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9a047de",
  null
  
)

export default component.exports