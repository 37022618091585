<template>
    <div class="user__mission">
        <el-row :gutter="20">
            <el-col :span="24" :xs="24">
                <el-form class="queryForm" :model="queryParams" ref="queryForm" style="display: flex;background: #FFFFFF;padding: 16px 14px 14px 14px;border-radius: 10px;">
                    <el-form-item label="下载链接: " label-width="110px">
                        <el-input
                            v-model="queryParams.app_store_id"
                            placeholder="请输入下载链接"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                    </el-form-item>

                    <el-form-item label="应用名称: " label-width="110px">
                        <el-input
                            v-model="queryParams.name"
                            placeholder="请输入应用名称"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                    </el-form-item>

                    <el-form-item label="开始时间：" label-width="110px">
                        <el-date-picker
                            v-model="queryParams.start_time"
                            type="datetime"
                            placeholder="选择开始日期时间">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="结束时间：" label-width="110px">
                        <el-date-picker
                            v-model="queryParams.end_time"
                            type="datetime"
                            placeholder="选择结束日期时间">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item style="margin-left: 40px;">
                        <el-button type="primary" icon="el-icon-search" size="medium" @click="handleQuery">查询
                        </el-button>
                        <el-button icon="el-icon-refresh" size="medium" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>

                <div class="mission__content">
                    <div class="mission__top">
                        <div style="display: flex;align-items: center;">
                            <div class="user__amount user__balance">
                                <el-image class="amount__icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/icon__balance.png'"></el-image>
                                <span>我的余额</span>
                                <span class="amount__data">{{ amount.amount || 0.00 }}</span>
                                <span>元</span>
                            </div>
                            <div class="user__amount user__overdrawn__amount">
                                <el-image class="amount__icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/icon__overdraft__limit.png'"></el-image>
                                <span>已透支金额</span>
                                <span class="amount__data">{{ amount.already_overdraw_amount || 0.00 }}</span>
                                <span>元</span>
                            </div>
                            <div class="user__overdraft__limit">
                                <span style="color: #D0021B;">*</span>
                                <span>可透支额度为：{{ amount.can_overdrawn_amount || 0.00 }}元</span>
                            </div>
                        </div>
                        <div class="add_btn">
                            <el-button type="primary" size="medium" @click="btnAddMission"  :copyId="null">新增任务</el-button>
                        </div>
                    </div>

                    <el-table v-loading="loading" :data="missionList" border stripe height="560">
                        <el-table-column label="应用名称" align="center" key="name" prop="name" width="250" :show-overflow-tooltip="true"/>
                        <el-table-column label="Logo" align="center">
                            <template slot-scope="scope">
                                <el-image :src="scope.row.logo" style="width: 50px;height:50px;"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="平台" align="center" key="platform" prop="platform" width="120" :show-overflow-tooltip="true"/>
                        <el-table-column label="下载链接" align="center" key="app_store_id" prop="app_store_id" width="500" :show-overflow-tooltip="true"/>
                        <el-table-column label="状态" align="center"  key="status" prop="status" width="120" :formatter="formatterState" />
                        <el-table-column label="下单数量" align="center" key="order_number" prop="order_number" width="120"/>
                        <el-table-column label="结算数量" align="center" key="settle_number" prop="settle_number" width="120"/>
                        <el-table-column label="单价" align="center" key="unit_price" prop="unit_price" width="150"/>
                        <el-table-column label="任务时间" align="center" width="360">
                            <template slot-scope="scope">
                                <div>{{ scope.row. start_time || '' }} - {{ scope.row.end_time || '' }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" align="center" key="create_time" prop="create_time" min-width="160" />
                        <el-table-column label="操作" align="center"  class-name="small-padding fixed-width" width="350" fixed="right">
                            <template slot-scope="scope">
                                <div class="btn__plain" style="margin-right: 20px;" @click="handleView(scope.row)">
                                    <el-image class="btn__icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/icon__view.png'"></el-image>
                                    <span>详情</span>
                                </div>
                                <div class="btn__plain" style="margin-right: 20px;" @click="handleMission(scope.row)">
                                    <el-image class="btn__icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/icon__export.png'"></el-image>
                                    <span>导出IDFA</span>
                                </div>
                                <div class="btn__plain" @click="handleCopy(scope.row)">
                                    <el-image class="btn__icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/icon__copy.png'"></el-image>
                                    <span>复制任务</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pagination v-show="total > 0" :total="total" :page.sync="pageInfo.page" :limit.sync="pageInfo.limit"
                            @pagination="getList"/>
                </div>
            </el-col>
        </el-row>

        <!-- 新增任务 -->
        <el-dialog title="创建任务" :visible.sync="open" width="1200px" :close-on-click-modal="false" class="add-task">
            <addTaskForm v-if="open" :copyId="copyId" @close="cancel" @success="taskSuccess"></addTaskForm>
        </el-dialog>

        <!-- 查看并编辑任务 -->
        <el-dialog title="任务详情" :visible.sync="updateOpen" width="1200px" :close-on-click-modal="false" class="update-task">
            <updateTaskForm :taskId="taskId" @close="cancel" @success="taskSuccess"></updateTaskForm>
        </el-dialog>

    </div>
</template>

<script>
import { taskList, taskDetail, userInfo } from '@/api/user'
import  Pagination  from '@/components/Pagination/index'
import addTaskForm from '@/components/AddTask/index'
import updateTaskForm from '@/components/updateTask/index'

export default {
    components: { Pagination, addTaskForm, updateTaskForm },
    data() {
        return {
            loading: false,
            // 查询参数
            queryParams: {
                app_store_id: null,
                name: null,
                start_time: null,
                end_time: null,
            },
            // 表格数据
            missionList: [],
            // 表格数据总条数
            total: 0,
            // 分页参数
            pageInfo: {
                page: 1,
                limit: 10,
            },
            // 任务状态表
            taskStatus: [
                { name: '未开始', value: 0 },
                { name: '进行中', value: 1 },
                { name: '已结束', value: 2 },
            ],
            // 当前查看的任务
            taskId: null,
            updateOpen: false,
            // 被复制的任务id
            copyId: 0,
            // 用户额度
            amount: {
                amount: null,
                already_overdraw_amount: null,
                can_overdrawn_amount: null
            },
            // 弹出层标题
            title: '',
            // 是否显示弹出层
            open: false,
            // 是否有未提交的新增
            form: {},
        }
    },
    mounted() {
        this.resetForm()
        this.getList()
        this.getBalance()
    },
    methods: {
        // 查询用户余额
        getBalance() {
            userInfo()
                .then(response => {
                    const { amount, already_overdraw_amount, can_overdrawn_amount } = response.data
                    this.amount = { amount, already_overdraw_amount, can_overdrawn_amount }
                })
        },

        // 查询任务列表
        getList(){
            this.loading = true;
            taskList({...this.queryParams, ...this.pageInfo})
            .then(response => {
                // console.log(response)
                this.missionList = response.data.list
                this.total = response.data.count
            })
            .catch(error => {
                console.error(error)
            })
            .finally(() => {
                this.loading = false
            })
        },

        //  任务状态改为汉字
        formatterState(row, column) {
            switch (row.status) {
                case 0:
                    return '未开始'
                    break;
                case 1:
                    return '进行中'
                    break;
                case 2:
                    return '已结束'
                    break;
            }
        },

        // 查询任务
        handleQuery() {
            this.pageInfo.page = 1
            this.getList()
        },

        // 重置查询任务参数
        resetQuery() {
            this.queryParams = {
              app_store_id: null,
              name: null,
              start_time: null,
              end_time: null,
            };
            this.handleQuery()
        },

        // 查看任务详情
        handleView(row) {
            this.taskId = row.id
            this.updateOpen = true
        },

        // 子组件点击取消
         cancel() {
            this.open = false
            this.updateOpen = false
            // this.reset()
        },

        // 添加任务成功，父组件的回调参数
        taskSuccess() {
            // console.log('添加任务成功，执行父组件的回调参数')
            this.getList()
        },

        // 复制任务
        handleCopy(row) {
            this.copyId = row.id
            this.open = true
        },

        handleMission() {},

        // 表单重置
        resetForm() {
            this.form = {
                logo: null,
                account: 0,
                appStoreId: null,
                productName: null,
                api: 0,
                remark: null,
                start: null,
                end: null,
                link: null,
                devices: [],
                systems: [],
                keywords: []
           }
        },

        // 新增任务按钮
        btnAddMission() {
            // this.reset()
            this.copyId = 0
            this.open = true
        },

        // 删除关键词
        handleRemoveKeyword(index) {
            this.form.keywords.splice(index, 1)
            this.form.keywords.forEach((item, i) => {
                this.$set(this.form.keywords, i, item)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.user__mission {
    background: #F2F2F2;
    padding: 20px;
    height: 100%;
}

.btn__plain {
    display: inline-flex;
    align-items: center;
    text-align: center;
    padding: 6px 10px;
    border: 1px solid #433FFF;
    border-radius: 4px;
    cursor: pointer;
    .btn__icon {
        width: 20px;
        height: 20px;
        margin-right: 3px;
    }
    span {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #433FFF;
        line-height: 14px;
    }
}

.mission__content {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
}

.mission__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
}

.user__amount {
    padding: 0 42px;
    height: 70px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    .amount__icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }

    span {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    .amount__data {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 28px;
        line-height: 28px;
    }
}

.user__balance {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/bg__balance.png');
    width: 320px;
    margin-right: 30px;

}

.user__overdrawn__amount {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/user/bg__overdraft__limit.png');
    width: 350px;
}

.user__overdraft__limit {
    margin-left: 10px;
    span {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
    }
}

.logo__uploader {
    width: 80px;
    height: 80px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;

    .logo__uploader__icon {
        margin: 25px;
        color: #434CEA;
        font-size: 30px;
    }
}

.add__keyword {
    cursor: pointer;
    .add__keyword__icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: #434CEA;
        margin-right: 4px;
        float: left;
        padding-top: 8px;
    }
    span {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #434CEA;
        line-height: 16px;
    }
}

.keyword__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .flex_row_center {
        display: flex;
        align-items: center;

    }

    .keyword__label {
        width: 70px;
        span {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #2A3A5A;
            line-height: 16px;
        }
    }

    .remove__keyword {
        cursor: pointer;
        margin-left: 10px;
        .remove__keyword__icon {
            width: 24px;
            height: 24px;
            font-size: 24px;
            color: #D0021B;
            margin-right: 4px;
            float: left;
            padding-top: 8px;
        }
        span {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #D0021B;
            line-height: 16px;
        }
    }
}
</style>

<style>
.queryForm .el-form-item {
    margin-bottom: 0;
}

.dialogForm .el-form-item {
    margin-bottom: 16px;
}
</style>
