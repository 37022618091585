const user = {
    state: {
        insideCode:'',//内部号
    },
    mutations: {
        M_INSIDE_CODE(state,value){
            state.insideCode = value
        },
    },

    actions: { },
}
export default user