<template>
    <div class="aso">
        <div v-if="showLarge">
            
            <div class="top_banner" :style="{backgroundImage: 'url('+largeBanner.img_src+')'}">
                <div class="main">
                    <div class="top_content">
                        <div class="content_left">
                            <div class="large_title">
                                <span>助力APP高效触达用户</span>
                            </div>
                            <div class="content_desc">
                                <span>助力APP高效触达用户，让你的APP被更多的人看到</span>
                            </div>
                            <div class="consult_btn">
                                <el-link @click="consultNow" :underline="false">
                                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/btn_consult.png'" style="width: 260px;height: 68px;"></el-image>
                                </el-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="why_asa">
                <div class="main">
                    <div class="content_title has_desc">
                        <span>为什么要做ASA</span>
                    </div>
                    <div class="content_title_desc">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_a_l.png'" style="width: 23px;height: 20px;"></el-image>
                        <div class="desc"><span>转化率高，价格低，ROI好</span></div>
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_a_r.png'" style="width: 23px;height: 21px;"></el-image>
                    </div>
                    <div class="why_content">
                        <el-row>
                            <el-col :span="6" v-for="(item, index) in reasons" :key="index">
                                <div class="why_item">
                                    <div class="item_box">
                                        <div class="item_title item_title_1">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="item_icon">
                                            <el-image :src="item.cover" style="width: 160px;height: 160px;"></el-image>
                                        </div>
                                        <div class="item_title item_title_2">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="item_content">
                                            <span>{{ item.content || '' }}</span>
                                        </div>
                                        <div class="consult_btn" @click="consultNow">
                                            <span>立即咨询</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <div class="how_asa">
                <div class="main">
                    <div class="content_title has_desc">
                        <span>ASA广告投放服务</span>
                    </div>
                    <div class="content_title_desc">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_a_l.png'" style="width: 23px;height: 20px;"></el-image>
                        <div class="desc"><span>高性价比托管式服务，无需额外增设投放人员，省时省力</span></div>
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_a_r.png'" style="width: 23px;height: 21px;"></el-image>
                    </div>
                    <div class="content_main">
                        <el-row>
                            <el-col :span="12" v-for="(item, index) in asoOption" :key="index">
                                <div class="aso_option_item">
                                    <div class="option_item_box" :class="'option_item_box_' + (index + 1)">
                                        <div class="option_box_row">
                                            <div class="option_box_icon">
                                                <el-image class="normal_icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_d_a.png'" style="width: 30px;height: 30px;"></el-image>
                                                <el-image class="hover_icon" :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_d_b.png'" style="width: 30px;height: 30px;"></el-image>
                                            </div>
                                            <div class="option_box_detail">
                                                <div class="item_title">
                                                    <span>{{ item.title || '' }}</span>
                                                </div>
                                                <div class="item_desc">
                                                    <span>{{ item.content || '' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <div class="success_case">
                <div class="main">
                    <div class="content_title">
                        <span>成功案例</span>
                    </div>
                    <div class="case_list">
                        <div class="case_item" v-for="(item, index) in cases" :key="index" :class="index === checkedCase ? 'active' : ''" @click="handleCaseChange(index)">
                            <div >
                                <el-image :src="item.cover" class="case_logo"></el-image>
                            </div>
                        </div>
                    </div>
                    <div class="case_detail" v-if="cases.length > 0">
                        <div style="width: 680px;padding-right: 40px;">
                            <div class="case_name">
                                <span>{{ cases[checkedCase].title || '' }}</span>
                            </div>
                            <div>
                                <div v-html="cases[checkedCase].content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <asa-mobile :reasons="reasons" :asoOption="asoOption" :cases="cases" v-else></asa-mobile>
    </div>
</template>

<script>
import asaMobile from './mobile.vue'
import { getList, getBanner } from '@/api/index'
import { consult } from '@/assets/consult'

export default {
    components: { asaMobile },
    props: ['screenType', 'clientWidth'],
    data() {
        return {
            showLarge: true,
            checkedType: 0, // 0-IOS；1-安卓
            reasons: [],
            asoOption: [],
            cases: [],
            checkedCase: 0,
            largeBanner: {},
        }
    },
    mounted() {
        this.showLarge = this.screenType === 'pc' || this.screenType === 'largePc' ? true : false

    },
    created() {
        this.getReasons()
        this.getOption()
        this.getAllCases()
        this.getLargeBanner()
    },
    watch: {
        screenType: {
            handler(oldVal, newVal) {
                this.showLarge = this.screenType === 'pc' || this.screenType === 'largePc' ? true : false
            },
            deep: true
        }
    },
    methods: {
        consultNow() {
            consult()
        },

        // 请求pc端banner
        getLargeBanner() {
            getBanner({code: 'asa'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        handleCaseChange(index) {
            this.checkedCase = index
        },

         // 请求为什么要做ASA
         getReasons() {
            getList({
                category_id: 10,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.reasons = response.data.data
            })
        },

        getOption() {
            getList({
                category_id: 11,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.asoOption = response.data.data
            })
        },

        // 请求全部优秀案例
        getAllCases() {
            getList({
                category_id: 26,
                page: 1,
                list_rows: 10000
            })
            .then(response => {
                const _data = response.data.data
                this.cases = response.data.data
            })
        }
        
    },
}
</script>

<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    background: #F4F4F4;
}

.content_title {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    span {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
    }
}

.content_title.has_desc {
    padding-bottom: 0;
}

.content_title_desc {
    padding-top: 17px;
    padding-bottom: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .desc {
        padding-left: 15px;
        padding-right: 15px;

        span {
            font-size: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #6361BE;
            line-height: 22px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .main {
        width: 1200px;
        margin: 0 auto;
    }
}

.top_banner {
    // background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_top.png');
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px) {
    .top_banner {
        height: 600px;

        .main {
            height: 100%;
        }

        .top_content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
        
        .large_title {
            padding-left: 10px;
            margin-bottom: 24px;

            span {
                font-size: 48px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 48px;
            }
        }

        .content_desc {
            padding-left: 10px;
            margin-bottom: 35px;

            span {
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 20px;
            }
        }

    }
}

.why_asa {
    background: #E8EBFF;
}

@media screen and (min-width: 1200px) {
    .why_content {
        padding-bottom: 60px;
        .why_item {
            padding: 22px 15px 32px 15px;
        }

        .item_box {
            padding: 50px 36px;
            background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
            border-radius: 20px;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(232, 235, 255, 1), rgba(232, 235, 255, 0), rgba(232, 235, 255, 1)) 1 1;
            text-align: center;
            transition: all 0.3s;
            height: 380px;
            overflow: hidden;

            .item_title {
                margin-bottom: 32px;

                span {
                    font-size: 28px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                }
            }

            .item_title_2 {
                display: none;
                margin-bottom: 15px;
            }

            .consult_btn {
                display: none;
                border-radius: 24px;
                margin-top: 25px;
                background: #fff;
                padding: 10px 0;
                span {
                    font-size: 16px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #5D3CDC;
                    line-height: 18px;
                }
            }

            .consult_btn:hover, .consult_btn:focus, .consult_btn:active {
                cursor: pointer;
            }

            .item_content {
                margin-top: 20px;

                span {
                    font-size: 14px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #919AA5;
                    line-height: 22px;
                }
            }
        }

        .item_box:hover, .item_box:focus, .item_box:active {
            background: linear-gradient(180deg, #A712B4 0%, #2456F8 100%);
            padding-top: 20px;
            padding-bottom: 33px;

            .item_title_1 {
                display: none;
            }

            .item_title_2 {
                display: block;
                margin-bottom: 15px;
            }

            .consult_btn {
                display: block;
            }

            .item_title {
                span {
                    color: #FFFFFF;
                }
            }

            .item_content {
                span {
                    color: #FFFFFF;
                }
            }
        }
    }
}

.how_asa {
    background: #F6F7FF;
}

@media screen and (min-width: 1200px) {
    .main {
        width: 1260px;
    }

    .how_asa {
        padding-bottom: 47px;
    }

    .content_title {
        padding-top: 58px;
        padding-bottom: 58px;
    }

    .content_main {
        .aso_option_item {
            // padding: 25px 30px 35px 30px;

            .option_item_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 91px;
                padding-right: 135px;
                height: 240px;
                background-size: cover;
                background-repeat: no-repeat;
                cursor: pointer;
                transition: all 0.2s;
                
                .option_box_row {
                    display: flex;
                    // align-items: center;
                }

                .option_box_icon {
                    margin-right: 14px;
                    .hover_icon {
                        display: none;
                    }
                }

                .item_title {

                    span {
                        font-size: 30px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        color: #6361BE;
                        line-height: 30px;
                    }
                }

                .item_desc {
                    display: none;
                    margin-top: 10px;

                    span {
                        font-size: 13px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 22px;
                    }
                }
            }

            .option_item_box:hover, .option_item_box:active, .option_item_box:focus {
                // background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_b.png');

                .option_box_icon {
                    .normal_icon {
                        display: none;
                    }

                    .hover_icon {
                        display: block;
                    }
                }

                .item_title {
                    span {
                        color: #FFFFFF;
                    }
                }

                .item_desc {
                    display: block;
                }
            }

            .option_item_box_1 {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_a.png')
            }

            .option_item_box_1:hover, .option_item_box_1:active, .option_item_box_1:focus {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_a_hover.png')
            }

            .option_item_box_2 {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_b.png')
            }

            .option_item_box_2:hover, .option_item_box_2:active, .option_item_box_2:focus {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_b_hover.png')
            }

            .option_item_box_3 {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_c.png')
            }

            .option_item_box_3:hover, .option_item_box_3:active, .option_item_box_3:focus {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_c_hover.png')
            }

            .option_item_box_4 {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_d.png')
            }

            .option_item_box_4:hover, .option_item_box_4:active, .option_item_box_4:focus {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/bg_c_d_hover.png')
            }
        }

        
    }

}

.success_case {
    background: #E8EBFF;
}

@media screen and (min-width: 1200px) {
    .case_list {
        display: flex;
        align-items: center;
        padding: 0 30px;

        .case_item {
            margin-right: 50px;
            padding-top: 36px;
            padding-bottom: 41px;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;
            height: 140px;
        }

        .case_logo {
            width: 64px;
            height: 64px;
            border-radius: 20px;
        }

        .case_logo::before {
            content: '';
            position: absolute;
            top: 0;
            left: 32px;
            width: 32px;
            height: 5px;
            background: transparent;
            border-radius: 2px;
        }

        .case_item.active {
            padding-top: 20px;
            padding-bottom: 25px;

            .case_logo {
                width: 96px;
                height: 96px;
                
            }

            .case_logo::before {
                background: #433FFF;
            }

        } 
    }

    .main {
        width: 1260px;
    }

    .case_detail {
        height: 543px;
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/image_e_a.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding-left: 70px;
        padding-top: 65px;

        .case_name {
            margin-bottom: 40px;

            span {
                font-size: 28px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 28px;
            }
        }
        .case_title {
            margin-bottom: 20px;

            span {
                font-size: 18px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 18px;
            }
        }

        .case_content {
            margin-bottom: 40px;

            span {
                font-size: 13px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
            }
        }
        .case_data {
            span {
                font-size: 48px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #433FFF;
                line-height: 48px;
            }
        }
    }
}

.nvkj_goal {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/bg_bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 78px;
    padding-bottom: 62px;

    h3 {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h6 {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
    }
}

</style>