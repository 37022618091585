<template>
    <div class="home">
        <div v-if="showLarge">
            <div class="top_banner" :style="{backgroundImage: 'url('+largeBanner.img_src+')'}">
                <div class="main">
                    <div class="top_content">
                        <div class="content_left">
                            <div class="large_title">
                                <span>帮你的小程序快速获取高排名</span>
                            </div>
                            <div class="content_desc">
                                <span>优质流量渠道，助力抢占微信10亿流量红利</span>
                            </div>
                            <el-link :underline="false" :href="largeBanner.link">
                                <div class="consult_btn" @click="consultNow">
                                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/btn_consult.png'" style="width: 260px;height: 68px;"></el-image>
                                </div>
                            </el-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mpaso_result">
                <div class="main">
                    <div class="content_title">
                        <span>小程序推广能为你带来什么</span>
                    </div>
                    <div class="result_row">
                        <el-row>
                            <el-col :span="12" v-for="(item, index) in brings" :key="index">
                                <div class="result_box" :style="{backgroundImage: 'url('+item.selected_background_img+')'}">
                                    <div class="result_title">
                                        <span>{{ item.title || '' }}</span>
                                    </div>
                                    <div class="result_contents">
                                        <div class="content_item" v-for="(con, ort) in item.content" :key="ort">
                                            <i class="el-icon-check"></i>
                                            <span>{{ con || '' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <div class="our_service">
                <div class="main">
                    <div class="content_title">
                        <span>我们的服务</span>
                    </div>
                    <div class="service_content">
                        <el-row>
                            <el-col :span="8" v-for="(item, index) in services" :key="index">
                                <div class="service_item">
                                    <div class="item_box">
                                        <div class="item_title item_title_1">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="item_icon">
                                            <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mpaso/icon__b__a.png'" style="width: 160px;height: 160px;"></el-image>
                                        </div>
                                        <div class="item_title item_title_2">
                                            <span>{{ item.title || '' }}</span>
                                        </div>
                                        <div class="item_content">
                                            <span>{{ item.content || '' }}</span>
                                        </div>
                                        <div class="consult_btn" @click="consultNow">
                                            <span>立即咨询</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <div class="service_range">
                <div class="main">
                    <div class="content_title">
                        <span>服务领域</span>
                    </div>
                    <div class="service_box">
                        <div class="service_box_title">
                            <span>无门槛所有类型小程序都欢迎前来合作</span>
                        </div>
                        <div class="service_box_desc">
                            <span>不论您是什么样的小程序，均可获取可观流量</span>
                        </div>
                        <div class="area__list">
                            <div class="area__item" v-for="(item, index) in areas" :key="index">
                                <div class="area__icon">
                                    <el-image :src="item.cover" style="width: 120px;height: 120px;"></el-image>
                                </div>
                                <div class="area__title">
                                    <span>{{ item.title || '' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="more_range">
                            <span>更多小程序类型请点击咨询客服</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="nvkj_goal">
                <h3>用数据驱动APP用户持续增长</h3>
                <h6>13年移动广告经验，魔女累计为 15000 个APP带来 上亿 个有效新增用户</h6>
            </div>
        </div>
        <mp-aso-mobile :brings="brings" :services="services" :areas="areas" v-else></mp-aso-mobile>
    </div>
</template>

<script>
import mpAsoMobile from './mobile.vue'
import { getList, getBanner } from '@/api/index'
import { consult } from '@/assets/consult'

export default {
    components: { mpAsoMobile },
    props: ['screenType', 'clientWidth'],
    data() {
        return {
            showLarge: true,
            results: [
                {title: '海量新用户', contents: ['自有高质流量入口，提升推广曝光能力', '不论是裂变起量还是冷启动都有效']},
                {title: '搜索排名提升', contents: ['风控防刷，真实高质用户，提升小程序权重', '让你的搜索排名一升再升']},
            ],
            brings: [],
            services: [],
            areas: [],
            largeBanner: {},
        }
    },
    mounted() {
        this.showLarge = this.screenType === 'pc' || this.screenType === 'largePc' ? true : false

    },
    created() {
        this.getBrings()
        this.getServices()
        this.getAreas()
        this.getLargeBanner()
    },
    watch: {
        screenType: {
            handler(oldVal, newVal) {
                this.showLarge = this.screenType === 'pc' || this.screenType === 'largePc' ? true : false
            },
            deep: true
        }
    },
    methods: {
        consultNow() {
            consult()
        },

        // 请求pc端banner
        getLargeBanner() {
            getBanner({code: 'appletAso'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        // 获取能带来什么
        getBrings() {
            getList({
                category_id: 16,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.brings = response.data.data
                for (let i = 0; i < this.brings.length; i ++) {
                    this.brings[i].content = JSON.parse(this.brings[i].content_json)
                }
            })
        },

        // 获取服务
        getServices() {
            getList({
                category_id: 17,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.services = response.data.data
            })
        },

        // 获取服务领域
        getAreas() {
            getList({
                category_id: 18,
                page: 1,
                list_rows: 10000
            })
            .then(response => {
                this.areas = response.data.data
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    background: #F4F4F4;
}

.content_title {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    span {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
    }
}

@media only screen and (min-width: 1200px) {
    .main {
        width: 1200px;
        margin: 0 auto;
    }
}

.top_banner {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mpaso/bg__top.png');
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px) {
    .top_banner {
        height: 600px;

        .main {
            height: 100%;
        }

        .top_content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
        
        .large_title {
            padding-left: 10px;
            margin-bottom: 24px;

            span {
                font-size: 48px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 48px;
            }
        }

        .content_desc {
            padding-left: 10px;
            margin-bottom: 35px;

            span {
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 20px;
            }
        }

    }
}

.mpaso_result {
    background: #E8EBFF;
}

@media screen and (min-width: 1200px) {
    .mpaso_result {
        .main {
            width: 1260px;
        }
        .result_row {
            .result_box {
                width: 638px;
                height: 330px;
                background-size: cover;
                background-repeat: no-repeat;
                padding: 88px 193px 128px 101px;
                .result_title {
                    margin-bottom: 24px;
                    span {
                        font-size: 30px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 30px;
                    }
                }
                .result_contents {
                    .el-icon-check {
                        color: #FFFFFF;
                        margin-right: 9px;
                    }

                    span {
                        font-size: 16px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 30px;
                    }
                }
            }
            .result_box_1 {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mpaso/bg__a__a.png');
            }
            .result_box_2 {
                background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/mpaso/bg__a__b.png');
            }
        }
    }
}

.our_service {
    background: #F6F7FF;
}

@media screen and (min-width: 1200px) {
    .service_content {
        padding-bottom: 60px;
        .service_item {
            padding: 22px 27px 32px 27px;
        }

        .item_box {
            padding: 50px 0;
            border-radius: 20px;
            background: #FFFFFF;
            text-align: center;
            transition: all 0.3s;
            height: 402px;
            overflow: hidden;

            .item_title {
                margin-bottom: 32px;

                span {
                    font-size: 28px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 28px;
                }
            }

            .item_title_2 {
                display: none;
                margin-bottom: 15px;
            }

            .consult_btn {
                display: none;
                border-radius: 24px;
                margin-top: 25px;
                background: #fff;
                padding: 15px 0;
                width: 240px;
                margin-left: auto;
                margin-right: auto;
                span {
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #5D3CDC;
                    line-height: 18px;
                }
            }

            .consult_btn:hover, .consult_btn:focus, .consult_btn:active {
                cursor: pointer;
            }

            .item_content {
                margin-top: 20px;
                padding: 0 67px;

                span {
                    font-size: 16px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #919AA5;
                    line-height: 22px;
                }
            }
        }

        .item_box:hover, .item_box:focus, .item_box:active {
            background: linear-gradient(180deg, #A712B4 0%, #2456F8 100%);
            padding-top: 20px;
            padding-bottom: 33px;

            .item_title_1 {
                display: none;
            }

            .item_title_2 {
                display: block;
                margin-bottom: 15px;
            }

            .consult_btn {
                display: block;
            }

            .item_title {
                span {
                    color: #FFFFFF;
                }
            }

            .item_content {
                span {
                    color: #FFFFFF;
                }
            }
        }
    }
}

.service_range {
    background: #E8EBFF;
    padding-bottom: 70px;
}

@media screen and (min-width: 1200px) {
    .service_box {
        background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
        box-shadow: 0px 5px 30px 0px rgba(126,137,211,0.13);
        border-radius: 20px;
        padding-top: 46px;
        padding-bottom: 39px;
    }

    .service_box_title {
        padding: 0 30px;
        margin-bottom: 29px;
        text-align: center;
        span {
            font-size: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 41px;
        }
    }
    .service_box_desc {
        text-align: center;
        padding: 0 30px;
        margin-bottom: 10px;
        span {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
        }
    }

    .area__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 60px;

        .area__item {
            text-align: center;
            width: 178px;
        }

        .area__title {
            padding-top: 10px;
            span {
                font-size: 18px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 18px;
            }
        }
    }

    .range_image {
        margin-top: 30px;
        text-align: center;
    }
    .more_range {
        margin-top: 60px;
        text-align: center;
        padding: 0 30px;
        span {
            font-size: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #5D3CDC;
            line-height: 18px
        }
    }
}

.nvkj_goal {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/bg_bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 78px;
    padding-bottom: 62px;

    h3 {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h6 {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
    }
}


</style>