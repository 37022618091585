import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import router from './router'
import { setStorage } from '@/utils/methods'

// NProgress.configure({ showSpinner: false })

// const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
    // console.log(to)
    const __token = getToken()
    if (to.path === '/login') {
        if (__token) {
            next({path: '/'})
        }
    }
    if (to.meta.requireAuth) {
        if (__token) {
            next()
        } else {
            setStorage('_prev_', to.path)
            next('/login')
        }
    } else {
        next()
    }
})

router.afterEach(() => {
    // NProgress.done()
})
