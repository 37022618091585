<template>
    <div class="home">
        <div  v-if="showLarge">
            <div class="top_banner" :style="{backgroundImage: 'url('+largeBanner.img_src+')'}">
                <div class="main">
                    <div class="top_content">
                        <div class="content_left">
                            <div class="large_title">
                                <span>减少安卓泛流量，增加下载率</span>
                            </div>
                            <div class="content_desc">
                                <span>减少安卓市场泛流量，增加精准用户下载率</span>
                            </div>
                            <el-link :underline="false" :href="largeBanner.link">
                                <div class="consult_btn" @click="consultNow">
                                    <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/btn_consult.png'" style="width: 260px;height: 68px;"></el-image>
                                </div>
                            </el-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="why_cpd">
                <div class="main">
                    <div class="content_title has_desc">
                        <span>为什么要做CPD服务</span>
                    </div>
                    <div class="content_title_desc">
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_a_l.png'" style="width: 23px;height: 20px;"></el-image>
                        <div class="desc"><span>减少安卓市场流量混杂问题，高效提升精准流量，降低成本</span></div>
                        <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/asa/icon_a_r.png'" style="width: 23px;height: 21px;"></el-image>
                    </div>
                    <div class="why_content">
                        <div class="reason_list">
                            <div class="reason_item" v-for="(item, index) in reasons" :key="index">
                                <div class="reason_box">
                                    <div style="display: flex;align-items: center;">
                                        <div class="reason_image">
                                            <el-image :src="item.cover" style="width: 72px;height: 72px;"></el-image>
                                        </div>
                                        <div class="reason_detail">
                                            <div class="reason_title">
                                                <span>{{ item.title || '' }}</span>
                                            </div>
                                            <div class="reason_content">
                                                <span>{{ item.content || '' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="consult_btn" @click="consultNow">
                                        <span>立即咨询</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right_image">
                            <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/image_a_a.png'" style="width: 484px;height: 556px;"></el-image>
                        </div>
                    </div>
                </div>
            </div>

            <div class="our_advantage">
                <div class="main">
                    <div class="content_title" style="padding-top: 58px;padding-bottom: 38px;">
                        <span>我们的优势</span>
                    </div>
                    <div class="advantage_content">
                        <div class="bg_row" style="flex-wrap: wrap;">
                            <div class=" bg_c" v-for="(item, index) in advantages" :key="index" :class="index % 2 === 0 ? 'bg_left' : 'bg_right'">
                                <div class="bg_title">
                                    <span>{{ item.title || '' }}</span>
                                </div>
                                <div class="bg_content">
                                    <span>{{ item.content || '' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="advantage_image">
                            <el-image :src="'https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/image_b_c.png'" style="width: 519px;height: 613px;"></el-image>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="nvkj_goal">
                <h3>用数据驱动APP用户持续增长</h3>
                <h6>13年移动广告经验，魔女累计为 15000 个APP带来 上亿 个有效新增用户</h6>
            </div> -->
        </div>
        <cpd-mobile v-else :reasons="reasons" :advantages="advantages"></cpd-mobile>
    </div>
</template>

<script>
import cpdMobile from './mobile.vue'
import { getList, getBanner } from '@/api/index'
import { consult } from '@/assets/consult'

export default {
    components: { cpdMobile },
    props: ['screenType', 'clientWidth'],
    data() {
        return {
            showLarge: true,
            reasons: [],
            advantages: [],
            largeBanner: {},
        }
    },
    mounted() {
        this.showLarge = this.screenType === 'pc' || this.screenType === 'largePc' ? true : false
    },
    created() {
        this.getReasons()
        this.getAdvantages()
        this.getLargeBanner()
    },
    watch: {
        screenType: {
            handler(oldVal, newVal) {
                this.showLarge = this.screenType === 'pc' || this.screenType === 'largePc' ? true : false
            },
            deep: true
        }
    },
    methods: {
        consultNow() {
            consult()
        },

        // 请求pc端banner
        getLargeBanner() {
            getBanner({code: 'cpd'})
            .then(response => {
                this.largeBanner = response.data[0]
            })
        },

        getReasons() {
            getList({
                category_id: 13,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.reasons = response.data.data
            })
        },

        getAdvantages() {
            getList({
                category_id: 14,
                page: 1,
                list_rows: 10
            })
            .then(response => {
                this.advantages = response.data.data
                console.log(this.advantages)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    background: #F4F4F4;
}

.content_title {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    span {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
    }
}

.content_title.has_desc {
    padding-bottom: 0;
}

.content_title_desc {
    padding-top: 17px;
    padding-bottom: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .desc {
        padding-left: 15px;
        padding-right: 15px;

        span {
            font-size: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #6361BE;
            line-height: 22px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .main {
        width: 1200px;
        margin: 0 auto;
    }
}

.top_banner {
    // background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/bg_top.png');
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px) {
    .top_banner {
        height: 600px;

        .main {
            height: 100%;
        }

        .top_content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
        
        .large_title {
            padding-left: 10px;
            margin-bottom: 24px;

            span {
                font-size: 48px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 48px;
            }
        }

        .content_desc {
            padding-left: 10px;
            margin-bottom: 35px;

            span {
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 20px;
            }
        }

    }
}

.why_cpd {
    background: #E8EBFF;
}

@media screen and (min-width: 1200px) {
    .why_content {
        display: flex;
        .reason_list {
            width: 760px;
            .reason_item {
                padding-top: 24px;
                
                .reason_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: linear-gradient(180deg, #F5F6FF 0%, #F8FCFD 100%);
                    box-shadow: 0px 5px 30px 0px rgba(126,137,211,0.13);
                    border-radius: 20px;
                    border: 1px solid;
                    border-image: linear-gradient(180deg, rgba(232, 235, 255, 1), rgba(232, 235, 255, 0), rgba(232, 235, 255, 1)) 1 1;
                    padding: 34px 40px 10px 40px;
                    transition: background 0.3s;
                }
                .reason_detail {
                    padding-left: 24px;
                    .reason_title {
                        margin-bottom: 10px;
                        max-width: 360px;
                        span {
                            font-size: 30px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                            line-height: 30px;
                            transition: color 0.3s;
                        }
                    }
                    .reason_content {
                        max-width: 360px;
                        span {
                            font-size: 13px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #919AA5;
                            line-height: 22px;
                            transition: color 0.3s;
                        }
                    }
                }
                .consult_btn {
                    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/bg_e_a.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 107px;
                    height: 107px;
                    padding: 25px 30px;
                    text-align: center;
                    transition: opacity 0.3s;
                    opacity: 0;
                    cursor: pointer;
                    span {
                        font-size: 18px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #B131BD;
                        line-height: 20px;
                    }
                }

            }
            .reason_item:hover, .reason_item:focus, .reason_item:active {
                .reason_box {
                    background: linear-gradient(135deg, #2456F8 0%,  #A712B4 100%);
                    border: 1px solid transparent;
                    box-shadow: none;
                }
                .reason_title {
                    span {
                        color: #FFFFFF;
                    }
                }
                .reason_content {
                    span {
                        color: #FFFFFF;
                    }
                }
                .consult_btn {
                    opacity: 1;
                }
            }
        }
    }
}

.our_advantage {
    background: #F6F7FF;
}

@media screen and (min-width: 1200px) {
    .main {
        width: 1252px;
    }
    .advantage_content {
        position: relative;
        padding-top: 97px;
        padding-bottom: 132px;
    }
    .advantage_image {
        width: 519px;
        height: 613px;
        position: absolute;
        top: 0;
        left: 396px;
    }
    .bg_row {
        display: flex;
        align-content: center;
        justify-content: space-between;
    }
    .bg_left {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/bg_c_l.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 52px 162px 54px 100px;
    }
    .bg_right {
        background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/cpd/bg_c_r.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 52px 100px 54px 170px;
        text-align: right;
    }
    .bg_c {
        width: 565px;
        height: 190px;
        .bg_title {
            margin-bottom: 12px;
            span {
                font-size: 28px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 28px;
            }
        }
        .bg_content {
            span {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #919AA5;
                line-height: 22px;
            }
        }
    }
}

.nvkj_goal {
    background-image: url('https://zhengqingchun.oss-cn-chengdu.aliyuncs.com/integral-wall/aso/bg_bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 78px;
    padding-bottom: 62px;

    h3 {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        margin-bottom: 25px;
    }

    h6 {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
    }
}

</style>