<template>
  <div class="user__detail">
    <el-row>
      <el-col :span="24" :xs="24">
        <el-form class="queryForm" :model="queryParams" ref="queryForm"
                 style="display: flex;background: #FFFFFF;padding: 16px 14px 14px 14px;border-radius: 10px;">
          <el-form-item label="收支类型" label-width="110px">
            <el-select v-model="queryParams.type" placeholder="请选择" style="width: 320px;">
              <el-option
                  v-for="item in types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="时间：" label-width="110px">
            <el-date-picker v-model="date" type="datetimerange" :picker-options="pickerOptions"  style="width: 400px;"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            align="right">
            </el-date-picker>
          </el-form-item>

          <el-form-item style="margin-left: 40px;">
            <el-button type="primary" icon="el-icon-search" size="medium" @click="handleQuery">查询
            </el-button>
            <el-button icon="el-icon-refresh" size="medium" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="detail__content">
          <el-table v-loading="loading" :data="detailList" border stripe>
            <el-table-column label="类型" align="center" key="type" prop="type"/>
            <el-table-column label="金额" align="center" key="amount" prop="amount"/>
            <el-table-column label="来源" align="center" key="source" prop="source"/>
            <el-table-column label="备注" align="center" key="remark" prop="remark"/>
            <el-table-column label="时间" align="center" key="update_time" prop="update_time"/>
          </el-table>
          <Pagination v-show="total > 0" :total="total" :page.sync="pageInfo.page" :limit.sync="pageInfo.limit"
                      @pagination="getList"/>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {detailType, detailList} from '@/api/user'
import Pagination from "@/components/Pagination/index.vue";

export default {
  components: {Pagination},
  data() {
    return {
      loading: false,
      // 查询参数
      queryParams: {
        type: null,
      },
      date: null,
      pageInfo: {
        page: 1,
        limit: 10,
      },
      // 收支类型
      types: [],
      // 表格数据
      detailList: [],
      pickerOptions: {
        shortcuts: [{
          text: '今日',
          onClick(picker) {
            let date = new Date();
            const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), '23', '59', '59');
            const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), '00', '00', '00');
            picker.$emit('pick', [start, end]);
          }
        },
          {
            text: '本周',
            onClick(picker) {
              let date = new Date();
              const end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 5, '23', '59', '59');
              const start = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1, '00', '00', '00');
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '本月',
            onClick(picker) {
              let date = new Date();
              const end = new Date(date.getFullYear(), date.getMonth(), new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(), '23', '59', '59');
              const start = new Date(date.getFullYear(), date.getMonth(), 1, '00', '00', '00');
              picker.$emit('pick', [start, end]);
            }
          }]
      },
    }
  },
  created() {
    this.getType()
    this.getList()
  },
  methods: {
    // 请求收支类型
    getType() {
      detailType().then(response => {
        this.types = response.data
      })
    },

    // 请求列表
    getList() {
      this.loading = true
      let params = {...this.queryParams, ...this.pageInfo};
      if (this.date) {
        params.date = (Date.parse(this.date[0]) / 1000) + '-' + (Date.parse(this.date[1]) / 1000);
      }
      detailList(params).then(response => {
        this.detailList = response.data.list
        this.total = response.data.count
      })
          .finally(() => {
            this.loading = false
          })
    },
    // 查询任务
    handleQuery() {
      this.pageInfo.page = 1;
      this.getList();
    },

    // 重置查询任务参数
    resetQuery() {
      this.queryParams = {
        type: null,
        date: null,
      };
      this.pageInfo.page = 1;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
$background-color1: #FFFFFF;
$background-color2: #F2F2F2;

.user__detail {
  background: $background-color2;
  padding: 20px;
  height: 100%;
}

.detail__content {
  padding: 24px;
  background: $background-color1;
  border-radius: 10px;
  margin-top: 20px;
}
</style>

<style>
.queryForm .el-form-item {
  margin-bottom: 0;
}
</style>